import { TempUnits } from '../../../../services';
import { convertToPreferredUnit } from '../../../../utils/temperature-util';
import { OccupancyType } from '../types';

export type ThresholdRecommendation = {
  thresholdName:
    | 'temperatureMaxThreshold'
    | 'occupiedHeatSetPoint'
    | 'occupiedCoolSetPoint'
    | 'unoccupiedHeatSetPoint'
    | 'unoccupiedCoolSetPoint';
  value: number;
};

export const getThresholdRecommendations = (
  prefTempUnit: TempUnits,
  equipmentTypeInstallation: string,
  occupancyType?: OccupancyType,
): ThresholdRecommendation[] => {
  const thresholdRecommendations: ThresholdRecommendation[] = [];

  // All values are in Celsius
  switch (equipmentTypeInstallation) {
    case 'freezer+built_in':
      // The Fahrenheit value is: 15
      thresholdRecommendations.push({
        thresholdName: 'temperatureMaxThreshold',
        value: convertToPreferredUnit(-9.44, 'temperature', prefTempUnit) ?? 0,
      });
      break;
    case 'refrigerator+built_in':
      // The Fahrenheit value is: 45
      thresholdRecommendations.push({
        thresholdName: 'temperatureMaxThreshold',
        value: convertToPreferredUnit(7.22, 'temperature', prefTempUnit) ?? 0,
      });
      break;
    case 'freezer+free_standing':
      // The Fahrenheit value is: 20
      thresholdRecommendations.push({
        thresholdName: 'temperatureMaxThreshold',
        value: convertToPreferredUnit(-6.67, 'temperature', prefTempUnit) ?? 0,
      });
      break;
    case 'refrigerator+free_standing':
      // The Fahrenheit value is: 48
      thresholdRecommendations.push({
        thresholdName: 'temperatureMaxThreshold',
        value: convertToPreferredUnit(8.89, 'temperature', prefTempUnit) ?? 0,
      });
      break;
    case 'hvac':
      /**
       * The Fahrenheit values are: 68, 74, 60, 85 respectively
       */
      if (!occupancyType || occupancyType === 'occupied') {
        thresholdRecommendations.push({
          thresholdName: 'occupiedHeatSetPoint',
          value: convertToPreferredUnit(20, 'temperature', prefTempUnit) ?? 0,
        });
        thresholdRecommendations.push({
          thresholdName: 'occupiedCoolSetPoint',
          value:
            convertToPreferredUnit(23.33, 'temperature', prefTempUnit) ?? 0,
        });
      }
      if (!occupancyType || occupancyType === 'unoccupied') {
        thresholdRecommendations.push({
          thresholdName: 'unoccupiedHeatSetPoint',
          value:
            convertToPreferredUnit(15.56, 'temperature', prefTempUnit) ?? 0,
        });
        thresholdRecommendations.push({
          thresholdName: 'unoccupiedCoolSetPoint',
          value:
            convertToPreferredUnit(29.44, 'temperature', prefTempUnit) ?? 0,
        });
      }
      break;
    case 'other':
    default:
      break;
  }

  return thresholdRecommendations;
};

import {
  DeviceSensorData,
  EquipmentTypeOptions,
  InstallationOptions,
  Metadata,
} from '../../../services';
import { METER_MODELS } from '../energy-dashboard-page/constants';
import { EnergyDeviceState } from '../energy-dashboard-page/dashboard-table/energy-table';
import { getMode } from '../energy-dashboard-page/utils/getMode';

export const toBase64 = (str: string): string =>
  Buffer.from(str, 'binary').toString('base64');
export const fromBase64 = (b64Str: string): string =>
  Buffer.from(b64Str, 'base64').toString('binary');

export const encodeEquipmentParams = ({
  equipmentId = null,
  deviceId,
  locationId,
  monitoringDeviceId,
}: {
  equipmentId: string | null;
  deviceId: string;
  locationId: string;
  monitoringDeviceId?: string;
}): string => {
  return toBase64(
    JSON.stringify({
      equipmentId,
      deviceId,
      locationId,
      monitoringDeviceId,
    }),
  );
};

type TaskType = {
  taskType: string;
  scheduledDateTime: string;
  url: string;
};

const isAlertSnoozed = (alertConfigs): boolean => {
  if (!alertConfigs) {
    return false;
  }
  const snoozedAlert = alertConfigs.find((config) => !!config.snoozeEndAt);

  return snoozedAlert
    ? new Date().getTime() < new Date(snoozedAlert.snoozeEndAt).getTime()
    : false;
};

const getTemperature = (
  temperature: number,
  latestSensorData: DeviceSensorData,
) => {
  const monitoringTemp = latestSensorData?.temperature;
  if (monitoringTemp || monitoringTemp === 0) {
    return temperature || temperature === 0 ? temperature : monitoringTemp;
  }
  return temperature;
};

const getHumidity = (humidity: number, latestSensorData: DeviceSensorData) => {
  const monitoringHum = latestSensorData?.humidity;
  if (monitoringHum || monitoringHum === 0) {
    return humidity || humidity === 0 ? humidity : monitoringHum;
  }
  return humidity;
};

export type EquipmentDetailsType = {
  isThermaControlled: boolean;
  deviceId: string;
  uuid: string;
  equipmentId: string | undefined;
  locationId: string;
  name: string;
  type: EquipmentTypeOptions;
  location: string;
  temperature: number;
  humidity: number;
  reading: string;
  current?: string;
  next?: TaskType;
  active?: boolean;
  state?: EnergyDeviceState;
  isAlertSnoozed: boolean;
  hasOverflowMenu: boolean;
  sensorSerialNumber?: string;
  vendorId?: string;
  vendor?: string;
  gatewayId?: string;
  setPoints?: {
    occupiedCoolSetPoint?: number;
    occupiedHeatSetPoint?: number;
    unoccupiedCoolSetPoint?: number;
    unoccupiedHeatSetPoint?: number;
  };
  installation: InstallationOptions;
  companyUUID: string;
  otherDescription?: string;
  meta?: {
    setPoint: number;
    temperatureMaxThreshold: number;
    temperatureMinThreshold: number;
    serialNumber: string;
    deviceSerialNumber: string;
    humidityMinThreshold?: number;
    humidityMaxThreshold?: number;
    status: 'active' | 'disabled';
  };
  originalMetadata?: Metadata;
  meterDevice?: Metadata;
};

export const normalizeEquipment = ({
  isThermaControlled,
  device,
  equipment,
  energyDevice,
  location,
  sensorData,
  alertConfigs,
  gateway,
  meterDevice,
}): EquipmentDetailsType => {
  if (isThermaControlled) {
    const isMeterOnlyEquipment = METER_MODELS.includes(
      energyDevice.metadata.model,
    );
    return {
      isThermaControlled,
      deviceId: device?.uuid || energyDevice.uuid,
      uuid: energyDevice.uuid,
      // NOTE: Added to be able to get deviceInfo using equipmentUUID
      equipmentId: equipment?.uuid,
      locationId: location?.uuid,
      name: isMeterOnlyEquipment
        ? equipment.name
        : energyDevice.metadata.name || energyDevice.name,
      type: energyDevice.equipmentType,
      location: location?.name,
      temperature: getTemperature(energyDevice.temperature, sensorData),
      humidity: getHumidity(energyDevice.humidity, sensorData),
      reading: energyDevice?.time ? energyDevice.time : sensorData?.time,
      active: energyDevice.active,
      state: energyDevice.state,
      current: getMode(energyDevice.active, energyDevice.state),
      next: energyDevice.tasks?.next ?? {
        taskType: '-',
        scheduledDateTime: '-',
      },
      isAlertSnoozed: isAlertSnoozed(alertConfigs.length && alertConfigs),
      hasOverflowMenu: !isMeterOnlyEquipment,
      vendorId: energyDevice.vendorId,
      vendor: energyDevice.deviceType.vendor,
      sensorSerialNumber: equipment?.meta.deviceSerialNumber,
      gatewayId: gateway?.gatewayId,
      setPoints: energyDevice.metadata,
      installation: equipment?.installation,
      companyUUID: equipment?.companyUUID,
      otherDescription: equipment?.otherDescription,
      // For equipment graph stuff
      meta: {
        setPoint: equipment?.setPoint,
        temperatureMaxThreshold: equipment?.meta.temperatureMaxThreshold,
        temperatureMinThreshold: equipment?.meta.temperatureMinThreshold,
        serialNumber: equipment?.meta.serialNumber,
        deviceSerialNumber: equipment?.meta.deviceSerialNumber,
        humidityMinThreshold: equipment?.meta.humidityMinThreshold,
        humidityMaxThreshold: equipment?.meta.humidityMaxThreshold,
        status: equipment?.meta.status,
      },
      originalMetadata: energyDevice.metadata,
      meterDevice: {
        ...meterDevice?.metadata,
        vendorId: meterDevice?.vendorId,
      },
    };
  }
  return {
    isThermaControlled,
    deviceId: device?.uuid,
    uuid: device?.uuid,
    equipmentId: equipment?.uuid,
    locationId: equipment?.locationUUID,
    name: equipment?.name,
    type: equipment?.equipmentType,
    location: location?.name,
    temperature: sensorData?.temperature,
    humidity: sensorData?.humidity,
    reading: sensorData?.time,
    isAlertSnoozed: isAlertSnoozed(alertConfigs.length && alertConfigs),
    hasOverflowMenu: true,
    sensorSerialNumber: device?.serialNumber,
    gatewayId: gateway?.gatewayId,
    installation: equipment?.installation,
    companyUUID: device?.companyUUID,
    otherDescription: equipment?.otherDescription,
    // For equipment graph stuff
    meta: {
      setPoint: equipment?.setPoint,
      temperatureMaxThreshold: equipment?.meta.temperatureMaxThreshold,
      temperatureMinThreshold: equipment?.meta.temperatureMinThreshold,
      serialNumber: equipment?.meta.serialNumber,
      deviceSerialNumber: equipment?.meta.deviceSerialNumber,
      humidityMinThreshold: equipment?.meta.humidityMinThreshold,
      humidityMaxThreshold: equipment?.meta.humidityMaxThreshold,
      status: equipment?.meta.status,
    },
    meterDevice: meterDevice?.metadata,
  };
};

export const arrayToKeyValueCommaSeparated = (object: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}) => {
  const array: string[] = [];
  Object.keys(object).map((key) => array.push(`${key}=${object[key]}`));
  return array.join(',');
};

import { roundNumber } from '@coinspect/utils';
import axios, { CancelTokenSource } from 'axios';
import { useContext, useEffect, useRef } from 'react';
import { Filters, GraphDataPoint } from 'src/store/reducers';

import {
  convertDataUnits,
  interpolateAlerts,
  matchControlEventClosestGraphTime,
  mutateHvacTempData,
} from '../components/temperature-humidity-graph/utils/graph-utils';
import {
  AlertNotifModel,
  AlertNotifService,
  DashboardBrowseParams,
  DataService,
  EnergyLocationService,
  LatestSensorData,
  SensorType,
  TempUnits,
} from '../services';
import { StoreContext } from '../store';
import { EquipmentGraphActions } from '../store/reducers/equipment-graph-reducer';

export function useDataService(preferredTempUnit?: TempUnits) {
  const { dispatch } = useContext(StoreContext);
  const browseGraphCancelToken = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    return () => {
      if (browseGraphCancelToken.current) {
        browseGraphCancelToken.current.cancel(
          'Cancelling browseGraph old request stacked on top.',
        );
      }
    };
  }, []);

  async function browseGraph(browseParams: DashboardBrowseParams) {
    if (browseGraphCancelToken.current) {
      browseGraphCancelToken.current.cancel(
        'Cancelling browseGraph old request stacked on top.',
      );
    }
    const newCancelToken = axios.CancelToken.source();
    browseGraphCancelToken.current = newCancelToken;

    dispatch({ type: 'browseGraph:set_REQUEST' });
    try {
      const { data = [] } = await DataService.browseGraph(
        browseParams,
        newCancelToken.token,
      );
      const { sensorType } = browseParams;

      dispatch({
        data: {
          graphData: data,
          sensorType,
        },
        type: `${sensorType}:graph:set`,
      });
    } finally {
      dispatch({ type: 'browseGraph:set_FINALLY' });
    }
  }

  async function browseEquipmentGraphData(
    browseParams: {
      end: string;
      start: string;
      hvacDeviceUUIDList?: string[];
      monDeviceUUIDList?: string[];
    },
    graphType: 'temperature' | 'humidity',
    type?: 'hvac',
    maxTempValue?: number,
  ) {
    const {
      end,
      start,
      hvacDeviceUUIDList = [],
      monDeviceUUIDList = [],
    } = browseParams;
    const hasEnergyDevices = !!hvacDeviceUUIDList?.length;
    const hasMonitoringDevices = !!monDeviceUUIDList?.length;
    const hasBothDeviceTypes = hasEnergyDevices && hasMonitoringDevices;
    const mergedDeviceUUIDList = [...hvacDeviceUUIDList, ...monDeviceUUIDList];

    if (browseGraphCancelToken.current !== null) {
      browseGraphCancelToken.current.cancel(
        'Cancelling browseGraph old request stacked on top.',
      );
    }
    const newCancelToken = axios.CancelToken.source();
    browseGraphCancelToken.current = newCancelToken;

    dispatch({ type: 'browseGraph:set_REQUEST' });
    try {
      const HVAC_TEMP_IDX = 0;
      const MON_TEMP_IDX = 1;
      const ALERTS_IDX = 2;
      const ECO_IDX = 3;
      const promises = [];

      // Get hvac graph data
      if (hasEnergyDevices) {
        promises[HVAC_TEMP_IDX] = EnergyLocationService.browseHvacGraphList({
          equipmentUUIDList: hvacDeviceUUIDList,
          startTime: start,
          endTime: end,
        });
      }

      // Get monitoring temperature graph data
      if (hasMonitoringDevices) {
        promises[MON_TEMP_IDX] =
          graphType === 'temperature'
            ? DataService.browseDeviceGraph(
                {
                  end,
                  start,
                  deviceUUID: monDeviceUUIDList,
                  sensorType: 'temperature',
                },
                newCancelToken.token,
              )
            : // humidity graph data
              DataService.browseDeviceGraph(
                {
                  end,
                  start,
                  deviceUUID: monDeviceUUIDList,
                  sensorType: 'humidity',
                },
                newCancelToken.token,
              );
        // Get active alerts graph data
        promises[ALERTS_IDX] = AlertNotifService.browse(
          {
            endDate: end,
            startDate: start,
            deviceUUID: mergedDeviceUUIDList,
            limit: 30,
          },
          newCancelToken.token,
        ) as Promise<AlertNotifModel[]>;
      }

      // Get eco mode graph data
      if (hasEnergyDevices) {
        promises[ECO_IDX] = DataService.getControlEvent({
          entityUUID: hvacDeviceUUIDList[0],
          endDate: end,
          executedDate: start,
          entityType: 'energy_device',
          startDate: start,
          status: 'enabled',
          type: [
            'load_shifting',
            'peak_shaving',
            'demand_response',
            'shifting',
          ],
        });
      }

      const [
        hvacResult,
        monResult,
        alertsResult,
        ecoResult,
      ] = await Promise.allSettled(promises);

      const fetchedGraphData: {
        temperature: GraphDataPoint[];
        humidity: GraphDataPoint[];
        ecoMode: unknown[];
      } = {
        temperature: [],
        humidity: [],
        ecoMode: [],
      };

      if (
        hvacResult.status === 'fulfilled' &&
        monResult.status === 'fulfilled' &&
        alertsResult.status === 'fulfilled' &&
        (ecoResult === undefined || ecoResult.status === 'fulfilled')
      ) {
        // Get fetched values from promises
        const hvacRequestData = hvacResult.value?.data ?? [];
        const hvacDataTransformed = mutateHvacTempData(
          hvacRequestData,
          graphType,
        );
        const monRequestData =
          (monResult.value?.data as GraphDataPoint[]) ?? [];

        const alertSentData = alertsResult.value?.data ?? null;
        const ecoData = ecoResult?.value?.data ?? null;

        // Transform temperature unit data if needed
        const hvacDataUnitConverted =
          graphType === 'temperature'
            ? convertDataUnits(
                hvacDataTransformed,
                'hvac',
                preferredTempUnit ?? 'f',
              )
            : hvacDataTransformed;
        const monDataUnitConverted =
          graphType === 'temperature'
            ? convertDataUnits(
                monRequestData,
                'monitoring',
                preferredTempUnit ?? 'f',
              )
            : monRequestData;

        // Merge both energy and monitoring data
        const allRequestedData = [
          ...hvacDataUnitConverted,
          ...monDataUnitConverted,
        ];

        // Sort data only if both device types are present
        const allDataSorted = hasBothDeviceTypes
          ? allRequestedData.sort(
              (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime(),
            )
          : allRequestedData;

        // If there are alerts sent interpolate them into the graph data
        const requestDataWithAlerts = alertSentData
          ? interpolateAlerts(alertSentData, allDataSorted, graphType)
          : allDataSorted;

        // Set temperature or humidity data depending on the graph type
        fetchedGraphData[graphType] = requestDataWithAlerts;

        // Set eco mode data if exists
        fetchedGraphData.ecoMode = ecoData
          ? matchControlEventClosestGraphTime(
              ecoData,
              hvacDataTransformed,
              maxTempValue,
              type,
            )
          : [];
      }

      dispatch({
        type: EquipmentGraphActions.setGraphData,
        data: {
          graphData: fetchedGraphData,
        },
      });
    } finally {
      dispatch({ type: 'browseGraph:set_FINALLY' });
    }
  }

  async function getEmployeeTimeSaved() {
    dispatch({
      type: 'employeeTimeSaved:fetch_REQUEST',
    });
    const { data } = await DataService.getEmployeeTimeSaved();

    dispatch({
      data: Number(data.toFixed(1)),
      type: 'employeeTimeSaved:set',
    });

    dispatch({
      type: 'employeeTimeSaved:fetch_FINALLY',
    });
  }

  function getLatestSensorData(
    devices: string[],
    sensorType: SensorType,
    start: string,
  ) {
    return DataService.getLatestSensorData({
      devices,
      sensorType,
      start,
    });
  }

  async function getAllLatestSensorData(devices: string[], filters: Filters) {
    dispatch({ type: 'latestSensorData:set_REQUEST' });
    const { sensorType = 'temperature' } = filters;
    const { start = '' } = filters.date;
    try {
      const [
        temperatureResult,
        batteryResult,
        humidityResult,
      ] = await Promise.all([
        await DataService.getLatestSensorData({
          devices,
          sensorType,
          start,
        }),
        await DataService.getLatestSensorData({
          devices,
          sensorType: 'battery',
          start,
        }),
        await DataService.getLatestSensorData({
          devices,
          sensorType: 'humidity',
          start,
        }),
      ]);

      if (!devices.length && !temperatureResult && !batteryResult) {
        return;
      }

      const result = temperatureResult ? temperatureResult : batteryResult;
      const deviceUUIDs = devices.length ? devices : Object.keys(result.data);
      const formattedData = deviceUUIDs.reduce(
        (data: LatestSensorData, deviceUUID: string) => {
          const { value: battery = null } =
            batteryResult.data[deviceUUID] || {};
          const { value: humidity = null } =
            humidityResult.data[deviceUUID] || {};
          const { value: sensorData = null } =
            temperatureResult.data[deviceUUID] || {};
          const { time: time = '' } = temperatureResult.data[deviceUUID] || {};

          data[deviceUUID] = {
            battery,
            temperature: roundNumber(sensorData, 1),
            humidity: roundNumber(humidity, 1),
            time,
          };

          return data;
        },
        {},
      );
      dispatch({
        data: formattedData,
        type: 'latestSensorData:set',
      });
    } finally {
      return dispatch({ type: 'latestSensorData:set_FINALLY' });
    }
  }

  return {
    browseGraph,
    getLatestSensorData,
    getAllLatestSensorData,
    getEmployeeTimeSaved,
    browseEquipmentGraphData,
  };
}

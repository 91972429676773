import { FormError, FormInput } from '@coinspect/ui';
import { isValidNumber, isValidSensorId } from '@coinspect/utils';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, FormContext } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import {
  AlertType,
  useAlertConfigService,
  useDeviceService,
} from '../../../hooks';
import { EnergyDeviceService, Metadata } from '../../../services';
import { StoreContext } from '../../../store';
import { EnergyFieldErrorStyle } from '../../../style/global-energy-styles';
import { convertToSavableTriggerValue } from '../../../utils/temperature-util';
import { Button } from '../../buttons';
import { EnergyDropdown } from '../../dropdown';
import { equipmentInstallation } from '../../equipment/equipment-installation';
import { transformToEquipmentPayloadData } from '../../equipment/equipment-utils';
import { useEquipmentService } from '../../equipment/use-equipment-service';
import ErrorBoundary from '../../error-boundary/error-boundary';
import EnergyModal from '../../modals/energy-modal';
import { Text } from '../../text';
import EnergyMobileNav from '../energy-dashboard-page/energy-mobile-nav';
import EnergyNav from '../energy-dashboard-page/energy-nav';
import AddIcon from '../energy-dashboard-page/icons/AddIcon';
import ArrowIcon from '../energy-dashboard-page/icons/ArrowIcon';
import { StyledButtonContents } from '../energy-people-page/index-styles';
import { EnergyReportsMainContent } from '../energy-reports-add/styles';
import { createLocationsDropdown } from '../energy-reports-add/utils';
import {
  EnergyReportsHeader,
  EnergyReportsHeaderMenu,
  EnergyReportsHeaderText,
  ReportsContent,
  ReportsPageContainer,
} from '../energy-reports-page/index-styles';
import {
  FORM_WIDTHS,
  FormSetpointContainer,
  ImageContainer,
  SubHeader,
} from './components/base';
import { SetpointBanner } from './components/set-point-banner';
import { SetpointInput } from './components/setpoint-input';
import {
  SetpointModule,
  SetpointModuleInputWrapper,
} from './components/setpoint-module';
import { useEditEquipmentForm } from './hooks/use-edit-equipment-form';
import { useGetEditEquipmentData } from './hooks/use-get-edit-equipment-data';
import { OccupancyType, UpdateEquipmentFormData } from './types';
import {
  convertToDisplayValue,
  convertToSavableTriggerValueOrNull,
  convertToSubmitValue,
} from './utils/form-temperature-value-converters';
import { getThresholdRecommendations } from './utils/get-threshold-recommendations';

const isValidOptional = (val: string): boolean =>
  val ? isValidNumber(val) : true;

export const EditEquipmentPage = () => {
  const [onSubmitError, setOnSubmitError] = useState('');
  const [isFormInvalid, setIsFormInvalid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showHumidityThresholds, setShowHumidityThresholds] = useState(false);
  const [hasAdvancedDetails, setHasAdvancedDetails] = useState(false);
  const [showEnlargeDeviceModal, setShowEnlargeDeviceModal] = useState(false);
  const [transitionStatus, setTransitionStatus] = useState('');

  const history = useHistory();

  const { dispatch } = useContext(StoreContext);

  const { updateEquipment, addEquipment } = useEquipmentService();
  const { checkIfUniqueSensorId, getHvacTransitionStatus } = useDeviceService();
  const { addAlertConfig } = useAlertConfigService();

  const {
    allLocations,
    currentEquipment,
    tempUnit,
    isHvac,
    isEcobeeVendor,
    isEditMode,
    user,
    goBackUrl,
    EQUIPMENT_TYPES,
  } = useGetEditEquipmentData();

  const formMethods = useEditEquipmentForm(currentEquipment, tempUnit);

  const { setValue } = formMethods;

  const isTransitionComplete = transitionStatus === 'completed';
  // FE-358 required setpoint fields should not display if vendor = ecobee.
  // However, to make dev work and further debugging easier, a variable hideSetPointFields was added.
  // If you need to work on setpoint fields then set hideSetPointFields = false.
  const hideSetPointFields = isEcobeeVendor;

  const clearOptionalAdvancedDetails = () => {
    formMethods.setValue('meta.setPoint', '');
    setHasAdvancedDetails(false);
  };

  const allowRecommendation =
    !isHvac &&
    equipmentInstallation
      .map(({ value }) => value)
      .filter((val) => val !== 'other')
      .includes(formMethods.watch('equipmentTypeInstallation'));

  const onCancel = () => {
    history.push(goBackUrl);
    dispatch({ type: 'browseGraph:set_RESET' });
  };

  const onSubmit = async (data: UpdateEquipmentFormData) => {
    setIsLoading(true);
    let metadata = {} as Metadata;
    if (isEcobeeVendor) {
      metadata = {
        name: data.name,
      };
    } else if (isHvac) {
      metadata = {
        ...currentEquipment.originalMetadata,
        occupiedCoolSetPoint: convertToSubmitValue(
          data.occupiedCoolSetPoint ?? '',
          tempUnit,
        ),
        occupiedHeatSetPoint: convertToSubmitValue(
          data.occupiedHeatSetPoint ?? '',
          tempUnit,
        ),
        unoccupiedCoolSetPoint: convertToSubmitValue(
          data.unoccupiedCoolSetPoint ?? '',
          tempUnit,
        ),
        unoccupiedHeatSetPoint: convertToSubmitValue(
          data.unoccupiedHeatSetPoint ?? '',
          tempUnit,
        ),
        name: data.name,
      };
    }

    setOnSubmitError('');

    if (isEditMode && currentEquipment) {
      try {
        if (isHvac) {
          await EnergyDeviceService.updateHvacDevice({
            deviceUUID: currentEquipment.deviceId,
            metadata,
          });
        } else {
          const thresholdAbove =
            convertToSavableTriggerValue(
              Number(data.meta?.temperatureMaxThreshold),
              'temperature',
              tempUnit || 'f',
            ) || 0;
          const thresholdBelow = convertToSavableTriggerValueOrNull(
            data.meta?.temperatureMinThreshold,
            tempUnit,
          );
          const setPoint = convertToSavableTriggerValueOrNull(
            data.meta?.setPoint,
            tempUnit,
          );

          if (currentEquipment.meta?.setPoint == null && setPoint) {
            mixpanel.track('Advanced Details Added', {
              equipment_name: currentEquipment.name,
              sensor_serial_number: currentEquipment.deviceId,
              location_name: currentEquipment.location,
              equipment_type: currentEquipment?.type,
              equipment_configuration: currentEquipment?.installation,
              advanced_details_properties: setPoint,
            });
          }

          await updateEquipment(
            {
              name: data.name,
              installation: currentEquipment.installation,
              equipmentType: currentEquipment.type,
              setPoint,
              otherDescription: data.otherDescription,
              meta: {
                serialNumber: currentEquipment.meta?.serialNumber ?? '',
                deviceSerialNumber: data.meta?.deviceSerialNumber ?? '',
                temperatureMinThreshold: thresholdBelow ?? undefined,
                temperatureMaxThreshold: thresholdAbove,
                humidityMaxThreshold: isValidNumber(
                  data.meta?.humidityMaxThreshold,
                )
                  ? Number(data.meta?.humidityMaxThreshold)
                  : undefined,
                humidityMinThreshold: isValidNumber(
                  data.meta?.humidityMinThreshold,
                )
                  ? Number(data.meta?.humidityMinThreshold)
                  : undefined,
                status: currentEquipment.meta?.status ?? 'disabled',
              },
              locationUUID: currentEquipment.locationId,
              companyUUID: currentEquipment.companyUUID,
            },
            currentEquipment.equipmentId,
          );
        }
        onCancel();
        history.go(0);
      } catch (err) {
        setOnSubmitError(err.response?.data.error[0].details);
      }
    } else {
      try {
        const isUnique = await checkIfUniqueSensorId({
          sensorId: data.meta?.deviceSerialNumber || '',
          deviceUUID: '',
        });

        if (!isUnique) {
          formMethods.setError(
            'meta.deviceSerialNumber',
            'noDuplicateSensorId',
          );
          setIsLoading(false);
          return;
        }

        data.meta = {
          ...data.meta,
          status: 'active',
        };

        const [_equipment, equipmentDevice] = await addEquipment(
          transformToEquipmentPayloadData(
            data,
            data.locationUUID,
            user?.company.uuid as string,
            tempUnit,
          ),
        );

        const BASE_ALERT_CONFIG = {
          recipients: [],
          triggerValue: undefined,
          field: 'battery' as AlertType,
          timeframe: 240,
          triggerComparator: '<',
        };

        await addAlertConfig({
          ...BASE_ALERT_CONFIG,
          deviceUUID: equipmentDevice.uuid,
          equipmentUUID: _equipment.uuid,
          methods: [],
          alertType: 'battery',
          recipients: [user?.uuid as string],
        });

        onCancel();
      } catch (err) {
        if (err.response?.data?.message) {
          setOnSubmitError(err.response.data.message);
        } else {
          const errorMessage =
            err.response?.data?.errors[0]?.details ||
            'Something went wrong, try again';
          setOnSubmitError(errorMessage);
        }
        window.scrollTo(0, 0);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const formData = formMethods.getValues() as UpdateEquipmentFormData;

    let validForm = Boolean(
      formData.name &&
        formData.locationUUID &&
        formData.equipmentTypeInstallation,
    );

    if (isHvac) {
      validForm =
        validForm &&
        (isEcobeeVendor
          ? true
          : Boolean(
              formData.occupiedHeatSetPoint &&
                formData.occupiedCoolSetPoint &&
                formData.unoccupiedHeatSetPoint &&
                formData.unoccupiedCoolSetPoint,
            ));
    } else {
      validForm =
        validForm &&
        isValidNumber(formMethods.getValues('meta.temperatureMaxThreshold')) &&
        isValidSensorId(formMethods.getValues('meta.deviceSerialNumber')) &&
        isValidOptional(
          formMethods.getValues('meta.temperatureMinThreshold'),
        ) &&
        isValidOptional(formMethods.getValues('meta.humidityMaxThreshold')) &&
        isValidOptional(formMethods.getValues('meta.humidityMinThreshold'));
    }

    setIsFormInvalid(!validForm);
  }, [formMethods.watch()]);

  useEffect(() => {
    if (isEditMode) {
      setValue('name', currentEquipment.name);
      setValue('locationUUID', currentEquipment.locationId);
      setValue(
        'equipmentTypeInstallation',
        ['other', 'hvac'].includes(currentEquipment.type)
          ? currentEquipment.type
          : `${currentEquipment.type}+${currentEquipment.installation}`,
      );
      setValue('otherDescription', currentEquipment.otherDescription);

      if (isHvac) {
        setValue(
          'occupiedHeatSetPoint',
          convertToDisplayValue(
            currentEquipment.setPoints?.occupiedHeatSetPoint ?? 0,
            tempUnit,
          ),
        );
        setValue(
          'occupiedCoolSetPoint',
          convertToDisplayValue(
            currentEquipment.setPoints?.occupiedCoolSetPoint ?? 0,
            tempUnit,
          ),
        );
        setValue(
          'unoccupiedHeatSetPoint',
          convertToDisplayValue(
            currentEquipment.setPoints?.unoccupiedHeatSetPoint ?? 0,
            tempUnit,
          ),
        );
        setValue(
          'unoccupiedCoolSetPoint',
          convertToDisplayValue(
            currentEquipment.setPoints?.unoccupiedCoolSetPoint ?? 0,
            tempUnit,
          ),
        );
      } else {
        setValue(
          'meta.temperatureMinThreshold',
          convertToDisplayValue(
            currentEquipment.meta?.temperatureMinThreshold ?? 0,
            tempUnit,
            true,
          ),
        );
        setValue(
          'meta.temperatureMaxThreshold',
          convertToDisplayValue(
            currentEquipment.meta?.temperatureMaxThreshold ?? 0,
            tempUnit,
            true,
          ),
        );
        if (currentEquipment.meta?.setPoint) {
          setHasAdvancedDetails(true);
        }
      }

      setIsFormInvalid(false);
    }
  }, [isEditMode, formMethods.watch('equipmentTypeInstallation')]);

  useEffect(() => {
    const getTransitionStatus = async () => {
      const result = await getHvacTransitionStatus(currentEquipment.deviceId);
      setTransitionStatus(result.hvacTransitionStatus);
    };
    getTransitionStatus();
  }, []);

  /**
   * Sets the recommended values based on Celsius.
   *
   */
  const setRecommended = useCallback(
    (occupancyType?: OccupancyType) => {
      const prefTempUnit = user?.preferredTempUnit || 'f';
      const { equipmentTypeInstallation } = formMethods.getValues();

      const recommendations = getThresholdRecommendations(
        prefTempUnit,
        equipmentTypeInstallation as string,
        occupancyType,
      );

      recommendations
        .map((recommendation) => ({
          thresholdName:
            recommendation.thresholdName === 'temperatureMaxThreshold'
              ? 'meta.temperatureMaxThreshold'
              : recommendation.thresholdName,
          value: recommendation.value.toString(),
        }))
        .forEach(({ thresholdName, value }) => {
          // Pass shouldValidate = true parameter to trigger re-validation
          formMethods.setValue(thresholdName, value, true);
        });
    },
    [formMethods, user?.preferredTempUnit],
  );

  return (
    <ErrorBoundary>
      <ReportsPageContainer>
        <EnergyNav page="HOME" />
        <EnergyMobileNav page="HOME" />
        <ReportsContent>
          <EnergyReportsHeader>
            <EnergyReportsHeaderMenu>
              <EnergyReportsHeaderText>
                <Link
                  to={goBackUrl}
                  onClick={() => dispatch({ type: 'browseGraph:set_RESET' })}
                >
                  <ArrowIcon
                    style={{
                      height: '24px',
                      transform: 'rotate(270deg)',
                      width: '24px',
                    }}
                  />
                </Link>
                {isEditMode ? 'Edit' : 'Add'} Equipment
              </EnergyReportsHeaderText>
            </EnergyReportsHeaderMenu>
          </EnergyReportsHeader>
          <FormContext {...formMethods}>
            <Form>
              <EnergyReportsMainContent>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div>
                    <div style={{ fontSize: '22px', marginBottom: '16px' }}>
                      Equipment
                    </div>
                    <p>
                      Add a unique name to easily identify your equipment in
                      your Dashboard, then select location and equipment type.
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div style={{ width: FORM_WIDTHS }}>
                    <FormInput
                      showrequired
                      className="energy-user-fields"
                      name="name"
                      label="Equipment name"
                      labelClassName="energy-user-label"
                      required
                      customErrors={{
                        required: 'Equipment name is required.',
                      }}
                      customStyle={EnergyFieldErrorStyle}
                    />
                    {onSubmitError && (
                      <FormError
                        error={{ type: 'submitError' }}
                        customErrors={{
                          submitError: `${onSubmitError}`,
                        }}
                        customStyle={EnergyFieldErrorStyle}
                      />
                    )}
                  </div>
                  <div>
                    <Controller
                      as={
                        <EnergyDropdown
                          search
                          options={createLocationsDropdown(
                            allLocations,
                            false,
                            { withAllLocations: false },
                          )}
                          disabled={isEditMode}
                          label="Equipment Location *"
                          width={FORM_WIDTHS}
                          error={!!formMethods.errors['locationUUID']}
                        />
                      }
                      name="locationUUID"
                      defaultValue={''}
                      rules={{
                        validate: {
                          required: (value) => value.length !== 0,
                        },
                      }}
                      onChange={(event) => event[1].value}
                    />

                    {formMethods.errors['locationUUID'] && (
                      <FormError
                        error={{ type: 'required' }}
                        customErrors={{
                          required: 'Please select a location.',
                        }}
                        customStyle={EnergyFieldErrorStyle}
                      />
                    )}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div>
                    <Controller
                      as={
                        <EnergyDropdown
                          options={EQUIPMENT_TYPES}
                          label="Equipment type *"
                          disabled={isEditMode}
                          width={FORM_WIDTHS}
                          error={
                            !!formMethods.errors['equipmentTypeInstallation']
                          }
                        />
                      }
                      name="equipmentTypeInstallation"
                      defaultValue={''}
                      rules={{
                        validate: {
                          required: (value) => value.length !== 0,
                        },
                      }}
                      onChange={(event) => {
                        if (!isEditMode) {
                          clearOptionalAdvancedDetails();
                        }
                        return event[1].value;
                      }}
                    />

                    {formMethods.errors['equipmentTypeInstallation'] && (
                      <FormError
                        error={{ type: 'required' }}
                        customErrors={{
                          required: 'Please select at least one location.',
                        }}
                        customStyle={EnergyFieldErrorStyle}
                      />
                    )}
                  </div>
                  {formMethods.watch('equipmentTypeInstallation') ===
                    'other' && (
                    <div style={{ width: FORM_WIDTHS }}>
                      <FormInput
                        showrequired
                        className="energy-user-fields"
                        name="otherDescription"
                        label="Other equipment type"
                        labelClassName="energy-user-label"
                        required
                        customErrors={{
                          required: 'Other equipment type is required.',
                        }}
                        customStyle={EnergyFieldErrorStyle}
                      />
                      {onSubmitError && (
                        <FormError
                          error={{ type: 'submitError' }}
                          customErrors={{
                            submitError: `${onSubmitError}`,
                          }}
                          customStyle={EnergyFieldErrorStyle}
                        />
                      )}
                    </div>
                  )}
                </div>
                {!isTransitionComplete &&
                  isEditMode &&
                  isHvac &&
                  currentEquipment.vendor !== 'ecobee' && <SetpointBanner />}
                {isHvac && !hideSetPointFields && (
                  <div style={{ display: 'flex', gap: '40px' }}>
                    <SetpointModule
                      type="occupied"
                      hspName="occupiedHeatSetPoint"
                      cspName="occupiedCoolSetPoint"
                      isDisabled={!isTransitionComplete}
                      onRecommendationClick={() => setRecommended('occupied')}
                    />
                    <SetpointModule
                      type="unoccupied"
                      hspName="unoccupiedHeatSetPoint"
                      cspName="unoccupiedCoolSetPoint"
                      isDisabled={!isTransitionComplete}
                      onRecommendationClick={() => setRecommended('unoccupied')}
                    />
                  </div>
                )}
                {!isHvac && (
                  <>
                    <div style={{ display: 'flex', gap: '40px' }}>
                      <div>
                        <SubHeader>Temperature threshold:</SubHeader>
                        <p style={{ color: '#A1AAAA' }}>
                          Add a temperature and threshold and later set alerts
                          for who and when to be
                          <br />
                          notified if your equipment goes above or below the
                          threshold.
                        </p>
                      </div>
                    </div>
                    <FormSetpointContainer>
                      <SetpointModuleInputWrapper>
                        <SetpointInput
                          className="energy-user-fields"
                          name="meta.temperatureMinThreshold"
                          label="Below"
                          labelClassName="energy-user-label"
                          customStyle={EnergyFieldErrorStyle}
                          hideErors
                          subText="Optional"
                          validation={{
                            validateNumber: isValidOptional,
                          }}
                        />
                        {formMethods.errors.meta?.temperatureMinThreshold && (
                          <FormError
                            error={{ type: 'validateNumber' }}
                            customErrors={{
                              validateNumber: 'Please enter a valid number.',
                            }}
                            customStyle={EnergyFieldErrorStyle}
                          />
                        )}
                      </SetpointModuleInputWrapper>
                      <SetpointModuleInputWrapper>
                        <SetpointInput
                          className="energy-user-fields"
                          name="meta.temperatureMaxThreshold"
                          label="Above *"
                          labelClassName="energy-user-label"
                          required
                          customStyle={EnergyFieldErrorStyle}
                          hideErors
                          validation={{
                            validateNumber: isValidOptional,
                          }}
                        />
                        {formMethods.errors.meta?.temperatureMaxThreshold && (
                          <FormError
                            error={{
                              type:
                                formMethods.errors.meta?.temperatureMaxThreshold
                                  ?.type || 'required',
                            }}
                            customErrors={{
                              required: 'Please set temperature threshold.',
                              validateNumber: 'Please enter a valid number.',
                            }}
                            customStyle={EnergyFieldErrorStyle}
                          />
                        )}
                      </SetpointModuleInputWrapper>
                      <div>
                        <Button
                          as="outline"
                          onClick={() => setRecommended()}
                          disabled={!allowRecommendation}
                        >
                          Use recommendation
                        </Button>
                        <Text
                          useNew
                          style={{
                            width: '330px',
                            color: '#A1AAAA',
                            marginTop: '10px',
                          }}
                        >
                          Recommended values are calculated based on your
                          selected equipment and health code standards. The
                          minimum and maximum threshold takes into account
                          expected temperature fluctuation to ensure that alerts
                          are only sent when equipment needs attention.
                        </Text>
                      </div>
                    </FormSetpointContainer>
                    {showHumidityThresholds || isEditMode ? (
                      <div style={{ width: FORM_WIDTHS }}>
                        <Text useNew asNew="title2">
                          Optional humidity threshold:
                        </Text>
                        <Text useNew style={{ color: '#A1AAAA' }}>
                          Add an optional humidity threshold if you would like
                          to later set humidity alerts. Humidity data is still
                          available without thresholds and alerts.
                        </Text>

                        <FormSetpointContainer>
                          <SetpointModuleInputWrapper>
                            <SetpointInput
                              className="energy-user-fields"
                              name="meta.humidityMinThreshold"
                              label="Below"
                              labelClassName="energy-user-label"
                              fieldSuffix="%"
                              subText="Optional"
                              hideErors // Hide errors as we are using custom error handling (prevents style issues)
                              validation={{
                                validateNumber: isValidOptional,
                              }}
                            />
                            {formMethods.errors.meta?.humidityMinThreshold && (
                              <FormError
                                error={{ type: 'validateNumber' }}
                                customErrors={{
                                  validateNumber:
                                    'Please enter a valid number.',
                                }}
                                customStyle={EnergyFieldErrorStyle}
                              />
                            )}
                          </SetpointModuleInputWrapper>
                          <SetpointModuleInputWrapper>
                            <SetpointInput
                              className="energy-user-fields"
                              name="meta.humidityMaxThreshold"
                              label="Above"
                              labelClassName="energy-user-label"
                              fieldSuffix="%"
                              subText="Optional"
                              hideErors // Hide errors as we are using custom error handling (prevents style issues)
                              validation={{
                                validateNumber: isValidOptional,
                              }}
                            />
                            {formMethods.errors.meta?.humidityMaxThreshold && (
                              <FormError
                                error={{ type: 'validateNumber' }}
                                customErrors={{
                                  validateNumber:
                                    'Please enter a valid number.',
                                }}
                                customStyle={EnergyFieldErrorStyle}
                              />
                            )}
                          </SetpointModuleInputWrapper>
                        </FormSetpointContainer>
                      </div>
                    ) : (
                      <Button
                        as="outline"
                        onClick={() =>
                          setShowHumidityThresholds(!showHumidityThresholds)
                        }
                        style={{ width: FORM_WIDTHS }}
                        hideOutline
                      >
                        <StyledButtonContents>
                          <AddIcon fill="#7DD2F6" />
                          &nbsp;ADD HUMIDITY THRESHOLDS
                        </StyledButtonContents>
                      </Button>
                    )}
                  </>
                )}

                {!(isEditMode && isHvac) && (
                  <div
                    style={{ marginTop: '60px', display: 'flex', gap: '40px' }}
                  >
                    <div style={{ width: FORM_WIDTHS }}>
                      <SubHeader>Device ID *</SubHeader>
                      <Text useNew style={{ color: '#A1AAAA' }}>
                        Input the serial number found on the sensor you are
                        associating with this equipment.{' '}
                      </Text>
                      <FormInput
                        showrequired
                        className="energy-user-fields"
                        name="meta.deviceSerialNumber"
                        label="Device ID"
                        labelClassName="energy-user-label"
                        required
                        customErrors={{
                          required: 'Device ID is required.',
                          noDuplicateSensorId:
                            'This sensor ID is already associated with a different equipment.',
                          isValidSensorId: 'This device ID is not valid',
                        }}
                        customStyle={EnergyFieldErrorStyle}
                        validation={{
                          isValidSensorId: (val: string) =>
                            !val ? true : isValidSensorId(val),
                        }}
                      />
                    </div>

                    <ImageContainer
                      onClick={() => setShowEnlargeDeviceModal(true)}
                    >
                      <img
                        style={{ width: '188px' }}
                        src={require('../../ui/images/sensor-ids/generic-sensor-id-dark.png')}
                        title="Device ID - Click to enlarge"
                      />
                      <Text
                        as="small"
                        useNew
                        style={{ color: '#A1AAAA', marginTop: '-20px' }}
                      >
                        Click to enlarge
                      </Text>
                    </ImageContainer>
                  </div>
                )}

                {!(isEditMode && isHvac) && (
                  <div>
                    {hasAdvancedDetails ? (
                      <div>
                        <SubHeader>Optional advanced details</SubHeader>
                        <Text useNew style={{ color: '#A1AAAA' }}>
                          Store your equipment manufacturer&apos;s information
                          for easy access whenever you need it.
                        </Text>
                        <SetpointInput
                          alignLabel="left"
                          className="energy-user-fields"
                          name="meta.setPoint"
                          label="Equipment setpoint"
                          labelClassName="energy-user-label"
                        />
                      </div>
                    ) : (
                      <Button
                        as="text"
                        onClick={() => setHasAdvancedDetails(true)}
                      >
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <AddIcon fill="#7DD2F6" /> Add advanced details
                        </span>
                      </Button>
                    )}
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    gap: '40px',
                    justifyContent: 'flex-end',
                    marginTop: '250px',
                  }}
                >
                  <Button onClick={onCancel} as="text">
                    Cancel
                  </Button>
                  <Button
                    onClick={formMethods.handleSubmit((data) => {
                      onSubmit(data);
                    })}
                    loading={isLoading}
                    disabled={isFormInvalid || isLoading}
                  >
                    Save
                  </Button>
                </div>
              </EnergyReportsMainContent>
            </Form>
          </FormContext>
        </ReportsContent>
        {showEnlargeDeviceModal && (
          <EnergyModal
            handleClose={() => setShowEnlargeDeviceModal(false)}
            title="Device ID"
            maxWidth="512px"
            setTopMargin="48px"
          >
            <Text style={{ color: '#FFF' }}>
              The Device ID is the serial number found on the front of the
              device
            </Text>
            <img
              style={{ width: '100%' }}
              src={require('../../ui/images/sensor-ids/generic-sensor-id-dark.png')}
            />
            <Text style={{ color: '#FFF' }}>
              Make sure your sensor is placed in an upright position.
            </Text>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '24px',
              }}
            >
              <Button
                as="text"
                onClick={() => setShowEnlargeDeviceModal(false)}
              >
                Okay
              </Button>
            </div>
          </EnergyModal>
        )}
      </ReportsPageContainer>
    </ErrorBoundary>
  );
};

export default EditEquipmentPage;

export const TEMPERATURE_COLOR = '#B2FFFF';
export const TEMPERATURE_COLOR_LIST = [
  '#b2ffff',
  '#ffbcf6',
  '#f9f871',
  '#fadaba',
  '#b6ffb5',
  '#ffaeae',
  '#d0dc88',
  '#8fcbff',
];
export const HUMIDITY_COLOR = '#D6D87E';
export const HUMIDITY_COLOR_LIST = [
  '#b2ffff',
  '#ffbcf6',
  '#f9f871',
  '#fadaba',
  '#b6ffb5',
  '#ffaeae',
  '#d0dc88',
  '#8fcbff',
];
export const ALERT_SENT_COLOR = '#FB8539';
export const ALERT_SENT_COLOR_LIST = [
  '#fb8539',
  '#fba539',
  '#fbc539',
  '#fbd539',
];
export const THRESHOLDS_COLOR = '#B2FFFF';
export const CSP_COLOR = '';
export const HSP_COLOR = '';
export const SETPOINT_COLOR = '#95BCF7';
export const GRID_COLOR = '#506169';

export const ANNOTATION_COLORS = {
  temperatureMinThreshold: THRESHOLDS_COLOR,
  temperatureMaxThreshold: THRESHOLDS_COLOR,
  humidityMinThreshold: THRESHOLDS_COLOR,
  humidityMaxThreshold: THRESHOLDS_COLOR,

  occupiedCoolSetPoint: '#FFC775',
  occupiedHeatSetPoint: '#FFC775',

  unoccupiedCoolSetPoint: '#F887EB',
  unoccupiedHeatSetPoint: '#F887EB',
};

export const LEGEND_COLORS = {
  thresholds: THRESHOLDS_COLOR,

  occupied: ANNOTATION_COLORS.occupiedCoolSetPoint,
  unoccupied: ANNOTATION_COLORS.unoccupiedCoolSetPoint,
};

export const ECO_COLOR = '#BE98F8';
export const ECO_COLOR_TRANSPARENT = 'rgba(190, 152, 248, 0.2)';

export const LINE_GRAPH_WIDTH = 3;
export const THRESHOLD_LINE_WIDTH = 1;

export const ACTUAL_GRAPH_IDX = 0; // for both temp and  humid graphs
export const ALERT_SENT_GRAPH_IDX = 1; // alert sent graph after the data graphs
export const ECO_MODE_GRAPH_START_IDX = 2; // eco mode after the alert sent data graph

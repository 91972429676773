import React from 'react';
import styled from 'styled-components';

import { ExclamationPointIcon } from '../../energy-dashboard-page/icons/ExclamationPointIcon';

const AlertContainer = styled.div`
  display: flex;
  border-radius: 8px;
  margin-top: 16px;
  padding: 4px 0px 0px 12px;
  border: 2px solid #47a1c3;
  max-width: 940px;
`;

const AlertLeft = styled.div`
  height: 34px;
  display: flex;
  align-self: flex-start;
`;

const AlertRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 8px;
  justify-content: center;
`;

const AlertMessage = styled.div`
  font-weight: 400;
`;

const AlertIcon = styled.div`
  width: 32px;
  height: 32px;
  padding: 6px 0px 6px 6px;
`;

const message = `Setpoints are under transition and 
    cannot be edited at this time. Please check back later.
`;

export const SetpointBanner = () => {
  return (
    <AlertContainer>
      <AlertLeft>
        <AlertIcon>
          <ExclamationPointIcon />
        </AlertIcon>
      </AlertLeft>
      <AlertRight>
        <AlertMessage>{message}</AlertMessage>
      </AlertRight>
    </AlertContainer>
  );
};

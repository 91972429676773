import { isValidNumber } from '@coinspect/utils';
import { FormContextValues, useForm } from 'react-hook-form';

import { TempUnits } from '../../../../services';
import { convertToPreferredUnit } from '../../../../utils/temperature-util';
import { EquipmentDetailsType } from '../../energy-equipment-page/utils';
import { UpdateEquipmentFormData } from '../types';

export const useEditEquipmentForm = (
  currentEquipment: EquipmentDetailsType,
  tempUnit: TempUnits,
): FormContextValues<UpdateEquipmentFormData> => {
  const formMethods = useForm<UpdateEquipmentFormData>({
    mode: 'onChange',
    defaultValues: {
      meta: {
        temperatureMaxThreshold: '',
        humidityMaxThreshold: isValidNumber(
          currentEquipment?.meta?.humidityMaxThreshold,
        )
          ? currentEquipment?.meta?.humidityMaxThreshold
          : undefined,
        humidityMinThreshold: isValidNumber(
          currentEquipment?.meta?.humidityMinThreshold,
        )
          ? currentEquipment?.meta?.humidityMinThreshold
          : undefined,
        deviceSerialNumber: currentEquipment?.meta?.deviceSerialNumber,
        setPoint: isValidNumber(currentEquipment?.meta?.setPoint)
          ? convertToPreferredUnit(
              currentEquipment?.meta?.setPoint ?? 0,
              'temperature',
              tempUnit,
            )?.toString()
          : undefined,
      },
    },
  });

  return formMethods;
};

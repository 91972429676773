import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { AccountContext, UserProfile } from '../../../../contexts';
import { LocationModel, TempUnits } from '../../../../services';
import { StoreContext } from '../../../../store';
import { equipmentInstallation } from '../../../equipment/equipment-installation';
import {
  encodeEquipmentParams,
  EquipmentDetailsType,
} from '../../energy-equipment-page/utils';

const HVAC_OPTION = [
  {
    text: 'HVAC',
    value: 'hvac',
  },
];

export const useGetEditEquipmentData = (): {
  allLocations: Record<string, LocationModel>;
  currentEquipment: EquipmentDetailsType;
  tempUnit: TempUnits;
  isHvac: boolean;
  isEcobeeVendor: boolean;
  isEditMode: boolean;
  goBackUrl: string;
  user: UserProfile | undefined | null;
  EQUIPMENT_TYPES: typeof equipmentInstallation;
} => {
  const { store } = useContext(StoreContext);
  const { user } = useContext(AccountContext);

  const location = useLocation<{ currentEquipment?: EquipmentDetailsType }>();

  const allLocations = store.pages.locations.allLocations.byUUID;
  const currentEquipment = location.state?.currentEquipment;
  const isHvac = currentEquipment?.type === 'hvac';
  const isEcobeeVendor = currentEquipment?.vendor === 'ecobee';

  const isEditMode = !!currentEquipment;

  const tempUnit = user?.preferredTempUnit || 'f';

  const goBackUrl = isEditMode
    ? `/energy/equipment/${encodeEquipmentParams({
        deviceId: currentEquipment?.deviceId,
        equipmentId: currentEquipment?.equipmentId || null,
        locationId: currentEquipment?.locationId,
        monitoringDeviceId: currentEquipment?.uuid,
      })}`
    : '/energy';

  const EQUIPMENT_TYPES = [
    ...(isEditMode ? HVAC_OPTION : []),
    ...equipmentInstallation,
  ];

  return {
    allLocations,
    currentEquipment,
    tempUnit,
    isHvac,
    isEcobeeVendor,
    isEditMode,
    goBackUrl,
    user,
    EQUIPMENT_TYPES,
  };
};

import config from '../../config';
import { LatestSensorData, TemperatureUnit } from '../../services';
import { DataStoreAction } from '../../store';

type SensorType = 'temperature' | 'humidity';

export const ONBOARDING_KEY = 'dashboard:onboarding:set';
export interface GraphDataPoint {
  device_uuid: string;
  value: number;
  time: string;
  mean?: number;
  hide?: boolean;
  alertSentTime?: string;
}

export interface HvacGraphDataPoint {
  energyDeviceUUID: string;
  humidity: number;
  temperature: number;
  isConnected: boolean;
  monitoringLocationUUID: string;
  timestamp: string;
  vendorId: string;
}

export interface Filters {
  date: {
    end?: string;
    start?: string;
    labelIndex: number;
  };
  devices: string[];
  sensorType?: SensorType;
  preloaded?: boolean;
}

export interface DashboardPageState {
  temperatureUnit: TemperatureUnit;
  filters: Filters;
  devicesColor: Record<string, string>;
  alertNotifTable: Record<string, unknown> & {
    totalCount: number;
  };
  latestSensorData: LatestSensorData;
  temperatureGraphData: GraphDataPoint[];
  humidityGraphData: GraphDataPoint[];
  showFilterRail: boolean;
  employeeTimeSaved: number;
  timeAlertFree: number;
  isOnboarding: boolean;
  keysWithGraph: string[];
  filterRailMeta: Record<string, unknown>;
  filterRailSearchKey: string;
  showThermaGoals: boolean;
  showNiceWork: boolean;
  flow: string;
  showCongratsScreen: boolean;
  isRepeatTutorial?: boolean;
  thermaGoal?: string;
  currentDesktopPage?: number; // description
  specificAlert?: string;
  currentPage?: unknown;
}

export enum DashboardActions {
  thermaGoalsClose = 'dashboard:thermaGoals:close',
  showQuizScreen = 'dashboard:onboarding:finish',
  showCongratsScreen = 'dashboard:onboarding:quiz:finish',
  closeCongratsScreen = 'dashboard:onboarding:congrats:finish',
  goDashboard = 'dashboard:modals:close',

  // TODO: move actions here
}

export function defaultFilters(state?: DashboardPageState): Filters {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 10);
  const timePeriod = {
    end: new Date().toISOString(),
    labelIndex: 6,
    start: startDate.toISOString(),
  };

  return {
    date: timePeriod,
    devices: [],
    sensorType: state ? state.filters.sensorType : 'temperature',
  };
}

function getDevicesToAdd(data: string[], state: DashboardPageState) {
  const newUUIDs = data.filter(
    (uuid: string) => state.filters.devices.indexOf(uuid) < 0,
  );
  const maxDeviceFilter = Number(config.maxDeviceFilter);
  const maxDeviceFilterDiff = maxDeviceFilter - state.filters.devices.length;
  const sliceEndIndex = maxDeviceFilterDiff < 0 ? 0 : maxDeviceFilterDiff;
  const truncatedNewUUIds = newUUIDs.slice(0, sliceEndIndex);

  return state.filters.devices.concat(truncatedNewUUIds);
}

export function dashboardPageReducer(
  state: DashboardPageState,
  action: DataStoreAction,
): DashboardPageState {
  const { data } = action;

  switch (action.type) {
    case 'alertNotif:reset':
    case 'alertNotif:append':
      return {
        ...state,
        alertNotifTable: {
          ...state.alertNotifTable,
          ...action.meta,
        },
      };
    case 'dashboard:filters:set':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.data,
        },
      };
    case 'dashboard:filters:set:default':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...defaultFilters(state),
        },
      };
    case 'dashboard:dateFilter:set':
      const { start, end, labelIndex } = action.data;
      return {
        ...state,
        filters: {
          ...state.filters,
          date: {
            end,
            labelIndex,
            start,
          },
        },
      };
    case 'dashboard:deviceFilter:add':
      return {
        ...state,
        filters: {
          ...state.filters,
          devices: getDevicesToAdd(action.data, state),
        },
      };

    case 'dashboard:deviceFilter:remove':
      const newDevices = action.data.reduce((acc: string[], device: string) => {
        const idx = acc.indexOf(device);
        return acc.slice(0, idx).concat(acc.slice(idx + 1));
      }, state.filters.devices);
      return {
        ...state,
        filters: {
          ...state.filters,
          devices: newDevices,
        },
      };

    case 'dashboard:deviceFilter:preload':
      return {
        ...state,
        filters: {
          ...state.filters,
          preloaded: true,
          devices: getDevicesToAdd(action.data, state),
        },
      };

    case 'dashboard:alert:preload':
      return {
        ...state,
        specificAlert: action.data,
      };

    case 'dashboard:deviceFilters:reset':
      return {
        ...state,
        filters: state.filters.preloaded
          ? {
              ...state.filters,
              preloaded: false,
            }
          : {
              ...state.filters,
              devices: [],
            },
      };

    case 'dashboard:devicesColor:set':
      return {
        ...state,
        devicesColor: action.data,
      };

    case 'temperature:set':
      return { ...state, temperatureUnit: action.data };

    // FILTER RAIL
    case 'dashboard:filterRail:toggle':
      return {
        ...state,
        showFilterRail: !state.showFilterRail,
      };

    case `temperature:graph:set`:
      return {
        ...state,
        temperatureGraphData: [...data.graphData].reverse(),
      };
    case `humidity:graph:set`:
      return {
        ...state,
        humidityGraphData: [...data.graphData].reverse(),
      };

    case 'employeeTimeSaved:set':
      return {
        ...state,
        employeeTimeSaved: action.data,
      };

    case 'timeAlertFree:set':
      return {
        ...state,
        timeAlertFree: action.data,
      };

    case 'latestSensorData:set':
      return {
        ...state,
        latestSensorData: action.data,
      };

    case 'dashboard:currentPage:set':
      return {
        ...state,
        currentPage: action.data.currentPage,
        currentDesktopPage: action.data.currentDesktopPage,
      };

    // ONBOARDING
    case 'dashboard:onboarding:set':
      return {
        ...state,
        isOnboarding: action.data,
      };

    case DashboardActions.goDashboard:
      return {
        ...state,
        isOnboarding: false,
        showThermaGoals: false,
        showCongratsScreen: false,
      };

    // show therma goals after finishing onboarding slides
    case DashboardActions.showQuizScreen:
      return {
        ...state,
        isOnboarding: false,
        showThermaGoals: true,
        showCongratsScreen: false,
      };
    case 'dashboard:onboarding:disableQuiz':
      return {
        ...state,
        isOnboarding: true,
        showThermaGoals: false,
        isRepeatTutorial: true,
      };
    case 'dashboard:onboarding:nice-work':
      return {
        ...state,
        showNiceWork: action.data.run ?? true,
        flow: action.data.flow ?? null,
        showCongratsScreen: false,
      };

    case DashboardActions.showCongratsScreen:
      return {
        ...state,
        showThermaGoals: false,
        showCongratsScreen: true,
      };
    case DashboardActions.closeCongratsScreen:
      return {
        ...state,
        showThermaGoals: false,
        showCongratsScreen: false,
      };

    case DashboardActions.thermaGoalsClose:
      return {
        ...state,
        showThermaGoals: false,
        showCongratsScreen: false,
      };

    // devices with graph data
    case 'dashboard:graph:keys:set':
      return {
        ...state,
        keysWithGraph: action.data,
      };

    case 'dashboard:filterRail:meta:set':
      return {
        ...state,
        filterRailMeta: action.data,
      };

    case 'dashboard:filterRail:search:set':
      return {
        ...state,
        filterRailSearchKey: action.data,
      };
    case 'onboarding:therma_goal:set': {
      return {
        ...state,
        thermaGoal: action.data,
      };
    }
  }
  return state;
}

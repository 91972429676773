import { formatNumber } from '@coinspect/ui';
import mixpanel from 'mixpanel-browser';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import {
  Fields,
  UserDetailsForm,
  UserDetailsValidationSchema,
} from '../components/users';
import { AccountContext, StandardModalContext, UserProfile } from '../contexts';
import { useAlertConfigService, useUserService } from '../hooks';
import { UserModel } from '../services';

interface EditUserOptions {
  header: string | JSX.Element;
  isFieldsDisabled: boolean;
}

export const useEditUser = () => {
  const { openModal } = useContext(StandardModalContext);

  function editUser(userItem: UserProfile, options: EditUserOptions): void {
    openModal({
      allContent: (
        <ModalForm
          userItem={userItem}
          isFieldsDisabled={options.isFieldsDisabled}
        />
      ),
      header: options.header,
    });
  }

  return {
    editUser,
  };
};

interface ModalFormProps {
  userItem: UserProfile;
  isFieldsDisabled: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ModalForm: React.FC<ModalFormProps> = (props: any) => {
  const { browseAlertConfigs } = useAlertConfigService();
  const { userItem, isFieldsDisabled } = props;
  const { hideModal } = useContext(StandardModalContext);
  const methods = useForm<UserDetailsValidationSchema>({
    defaultValues: {
      ...userItem,
      phoneNumber: userItem.phoneNumber
        ? formatNumber(userItem.phoneNumber as string)
        : '',
    },
    mode: 'onChange',
  });

  const { editUsers, editSelf } = useUserService();
  const account = useContext(AccountContext);
  const { user, setAuthedUser } = account;
  const userEditUUID = userItem.uuid;
  const ownAccount = user?.uuid === userEditUUID;
  async function handleSubmit(data: Fields) {
    const res = await (ownAccount
      ? editSelf(data as UserModel)
      : editUsers(userEditUUID, data as UserModel));
    if (res.fail) {
      return res.fail;
    }

    // refresh alert configs when pref temp unit is changed
    if (ownAccount) {
      setAuthedUser((res as unknown) as UserProfile);

      if (userItem.preferredTempUnit !== res.preferredTempUnit) {
        await browseAlertConfigs(res.preferredTempUnit);
      }
    }

    mixpanel.track('Profile Edited', {
      user_name: `${data.firstName} ${data.lastName}`,
      user_email: data.email,
      user_role: data.role,
      company_name: user?.company.name,
      updated_fields: Object.keys(data),
    });

    hideModal();
  }

  function showRoleToggle(): boolean {
    return !(userItem.role === 'account_owner' || !!ownAccount);
  }

  return (
    <UserDetailsForm
      fields={userItem}
      uuid={userEditUUID}
      onSubmit={handleSubmit}
      withRoleToggle={showRoleToggle()}
      withNameFields={ownAccount}
      onCancel={hideModal}
      validationOnLoad
      formMethods={methods}
      isModal={true}
      disableField={isFieldsDisabled}
      disableEmail={isFieldsDisabled}
    />
  );
};

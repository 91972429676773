import React from 'react';
import styled from 'styled-components';

import SimpleCloseIcon from '../pages/energy-dashboard-page/icons/ModalCloseIcon';

const ModalOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(174, 170, 174, 0.25);
  z-index: 15;

  &.disable-overlay {
    height: auto;
    width: auto;
    position: static;
    background: transparent;
    z-index: 0;
  }
`;

export const ModalContainer = styled.div<{ setTopMargin: string }>`
  margin: calc(100vh / 4) auto 0 auto;
  border-radius: 28px;
  background: linear-gradient(
      0deg,
      rgba(145, 208, 242, 0.05) 0%,
      rgba(145, 208, 242, 0.05) 100%
    ),
    #121212;
  box-shadow: 0px 2px 6px 2px rgba(18, 18, 18, 0.15),
    0px 1px 2px 0px rgba(18, 18, 18, 0.3);
  padding: 24px;

  &.set-top {
    margin-top: ${({ setTopMargin }) => setTopMargin};
  }
`;

const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  padding: 0 8px;
  margin-bottom: 24px;
  position: relative;
`;
const IconModalTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  padding: 0 8px;
  margin-bottom: 24px;
  position: relative;
`;
const ModalCloseIconWrapper = styled.div<{ disabled?: boolean }>`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 8px;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  opacity: ${({ disabled }) => (disabled ? '50%' : '100%')};
`;

const ModalBody = styled.div`
  padding: 0 8px;
`;

const preventPropagation = (e) => e.stopPropagation();
const ModalCloseIcon = (props) => (
  <ModalCloseIconWrapper {...props}>
    <SimpleCloseIcon />
  </ModalCloseIconWrapper>
);

const EnergyModal = ({
  handleClose,
  title,
  titleIcon,
  children,
  maxWidth = '400px',
  disableClose = false,
  hideOverlay = false,
  setTopMargin,
}: {
  handleClose: () => void;
  title: string;
  titleIcon?: JSX.Element;
  children: JSX.Element;
  maxWidth?: string;
  disableClose?: boolean;
  hideOverlay?: boolean;
  setTopMargin?: string;
}) => {
  return (
    <ModalOverlay
      className={`${hideOverlay ? 'disable-overlay' : ''}`}
      onClick={!disableClose ? handleClose : undefined}
    >
      <ModalContainer
        style={{ maxWidth }}
        className={`${setTopMargin ? 'set-top' : ''}`}
        onClick={preventPropagation}
        setTopMargin={setTopMargin}
      >
        {titleIcon ? (
          <>
            <IconModalTitle>
              {titleIcon}
              <ModalCloseIcon
                onClick={!disableClose ? handleClose : undefined}
                disabled={disableClose}
              />
            </IconModalTitle>
            <IconModalTitle>{title}</IconModalTitle>
          </>
        ) : (
          <ModalTitle>
            {title}
            <ModalCloseIcon
              onClick={!disableClose ? handleClose : undefined}
              disabled={disableClose}
            />
          </ModalTitle>
        )}
        <ModalBody>{children}</ModalBody>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default EnergyModal;

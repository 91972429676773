import { roundNumber, toCelsius, toFahrenheit } from '@coinspect/utils';

import { TempUnits } from '../../../../services';
import { convertToPreferredUnit } from '../../../../utils/temperature-util';

export const convertToSubmitValue = (
  value: string,
  tempUnit: TempUnits,
): number => {
  const asNumber = Number(value);

  if (tempUnit === 'c') {
    return toFahrenheit(asNumber);
  }

  return asNumber;
};

export const convertToDisplayValue = (
  value: number,
  tempUnit: TempUnits,
  storedAsCelsius?: boolean,
): string => {
  if (storedAsCelsius) {
    return (
      convertToPreferredUnit(value, 'temperature', tempUnit)?.toString() ?? '0'
    );
  }

  if (tempUnit === 'c') {
    return String(roundNumber(toCelsius(value), 2));
  }

  return String(roundNumber(value, 2));
};

export const convertToSavableTriggerValueOrNull = (
  val: string | undefined,
  tempUnit: TempUnits,
): number | null => {
  const salvableVal = val ? Number(val) : null;

  if (salvableVal && tempUnit === 'f') {
    return roundNumber(toCelsius(salvableVal), 2) ?? 0;
  }

  return salvableVal;
};

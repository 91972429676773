import { FormError } from '@coinspect/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Icon } from 'semantic-ui-react';

import { EnergyFieldErrorStyle } from '../../../../../style/global-energy-styles';
import { Button } from '../../../../buttons';
import SettingsSuggest from '../../../energy-dashboard-page/icons/SettingsSuggest';
import { OccupancyType } from '../../types';
import { SetpointInput } from '../setpoint-input';
import {
  ButtonContentContainer,
  Description,
  SetpointModuleContainer,
  SetpointModuleInputContainer,
  SetpointModuleInputWrapper,
  SubHeader,
} from './base';

type SetpointModuleProps = {
  type: OccupancyType;
  hspName: string;
  cspName: string;
  isDisabled: boolean;
  onRecommendationClick?: () => void;
};

export const SetpointModule = ({
  type,
  hspName,
  cspName,
  isDisabled,
  onRecommendationClick,
}: SetpointModuleProps) => {
  const formMethods = useFormContext();
  return (
    <SetpointModuleContainer>
      <SubHeader>Setpoints for {type} hours</SubHeader>
      <Description>
        Set temperatures for the hours that this location is{' '}
        {type === 'occupied' ? type : 'empty'}. The heating setpoint indicates
        how cold the space will be before starting to heat. The cooling setpoint
        indicates how warm the space will be before starting to cool.{' '}
      </Description>
      <SetpointModuleInputContainer>
        <SetpointModuleInputWrapper>
          <SetpointInput
            className="energy-user-fields"
            name={hspName}
            label="Heating setpoint"
            labelClassName="energy-user-label"
            disabled={isDisabled}
          />

          {formMethods.errors[hspName] && (
            <FormError
              error={{ type: 'required' }}
              customErrors={{
                required: 'Please set heating setpoint.',
              }}
              customStyle={EnergyFieldErrorStyle}
            />
          )}
        </SetpointModuleInputWrapper>
        <SetpointModuleInputWrapper>
          <SetpointInput
            className="energy-user-fields"
            name={cspName}
            label="Cooling setpoint"
            labelClassName="energy-user-label"
            disabled={isDisabled}
          />
          {formMethods.errors[cspName] && (
            <FormError
              error={{ type: 'required' }}
              customErrors={{
                required: 'Please set cooling setpoint.',
              }}
              customStyle={EnergyFieldErrorStyle}
            />
          )}
        </SetpointModuleInputWrapper>
      </SetpointModuleInputContainer>
      {onRecommendationClick && (
        <Button
          disabled={isDisabled}
          as="text"
          style={{ alignSelf: 'center', marginTop: '12px' }}
          onClick={onRecommendationClick}
        >
          <ButtonContentContainer>
            <Icon fill="#97DBF8" as={SettingsSuggest} />
            <span>Use Recommendation</span>
          </ButtonContentContainer>
        </Button>
      )}
    </SetpointModuleContainer>
  );
};

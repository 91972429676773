export const FREEZER = 'freezer';
export const REFRIGERATOR = 'refrigerator';
export const OTHER = 'other';
export const HVAC = 'hvac';

export const equipmentTypeMap: Record<string, string> = {
  [FREEZER]: 'Freezer',
  [REFRIGERATOR]: 'Refrigerator',
  [OTHER]: 'Other',
  [HVAC]: 'HVAC',
};

export const equipmentTypeOptions = Object.entries(equipmentTypeMap)
  .filter(([key]) => key !== HVAC)
  .map(([key, value]) => ({ text: value, value: key }));

export const equipmentTypeOptionsWithHVAC = Object.entries(equipmentTypeMap)
  .filter(([key]) => key !== OTHER)
  .map(([key, value]) => ({ text: value, value: key }));

export const equipmentInstallOptions = [
  {
    text: 'Reach-in',
    value: 'free_standing',
  },
  {
    text: 'Walk-in',
    value: 'built_in',
  },
];

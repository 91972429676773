import styled from 'styled-components';

export const SetpointModuleContainer = styled.div`
  display: flex;
  background-color: #1c2124;
  width: 500px;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
`;

export const ButtonContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SubHeader = styled.h3`
  font-size: 18px;
  font-weight: 400;
  font-family: proxima-nova;
`;

export const Description = styled.p`
  color: #a1aaaa;
`;

export const SetpointModuleInputContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-around;
`;

export const SetpointModuleInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
`;

import {
  darkViolet80,
  FlexPaddedContent,
  FormDropdown,
  FormInput,
  PillContent,
  ThermaSecondaryAction400,
} from '@coinspect/ui';
import get from 'lodash/get';
import omit from 'lodash/omit';
import mixpanel from 'mixpanel-browser';
import moment from 'moment-timezone';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, DropdownItemProps, Form } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  DesktopAndTablet,
  mobileMaxWidth,
  mobileMaxWidthPx,
  MobileOnly,
  tabletMaxWidthPx,
  tabletMinWidthPx,
  w414,
  w415,
  w540,
} from '../../../constants';
import {
  AlertNotifConfigPayload,
  AlertType,
  NormalAlertModel,
  useFeatures,
} from '../../../hooks';
import { isFormAcceptable } from '../../../lib/isFormAcceptable';
import { StoreContext } from '../../../store';
import { SelectedOptionsType } from '../../devices/device-alert-config-list';
import { AlertSchedulerModal } from '../../modals/alert-scheduler-modal';
import timeframes from '../timeframes';
export const DEFAULT_BATTERY_VALUE = 15;
export const DEFAULT_SENSOR_NAME = 'the device';
import { NotificationMethod } from '@coinspect/atka-types';
import { isValidNumber } from '@coinspect/utils';
import isEmpty from 'lodash/isEmpty';
import { Media } from 'src/contexts/app-media';

import { AccountContext } from '../../../contexts';
import { AddTextButton } from '../../buttons';
import {
  createAboveBelowAlerts,
  createAboveBelowTempHumidAlerts,
  createTempHumidAlerts,
  determineTriggerValue,
} from '../../equipment-alert-config/equipment-alert-config-utils';
import { EquipmentPayloadData } from '../../equipment/equipment-service';
import { FormCloseButton } from '../../forms';
import { NotifMethodOptions } from '../../users';

interface TempDataInterface {
  minimumTemp?: number;
  maximumTemp: number;
  maximumHumidity?: number;
  minimumHumidity?: number;
}

const typeOptionsValues = {
  temperatureAndHumidity: 'both',
  temperature: 'temperature',
  humidity: 'humidity',
};

export interface AlertConfigFormProps {
  defaultValues: AlertNotifConfigPayload;
  deviceUUID?: string;
  hasActiveAlerts: (hasScheduledAlerts: boolean) => void;
  onCancel?: () => Promise<void> | void;
  onSubmit: (
    payload: AlertNotifConfigPayload,
    isAddAnother: boolean,
  ) => Promise<void> | void;
  deleteButton?: JSX.Element | null;
  formType?: 'add' | 'edit';
  isFirstAlert?: boolean;
  isRecommendedAlert?: boolean;
  recommendedOptionsSelected?: SelectedOptionsType;
  index?: number;
  tempData?: TempDataInterface;
  equipmentUUID?: string;
}

export interface AlertConfigFormFields {
  field: AlertType;
  recipients: string[];
  timeframe: number;
  triggerComparator: string;
  triggerValue?: number;
  isUpdating?: boolean;
  schedule?: {
    rules: unknown[];
    settings: {
      timezone: string;
    };
  };
  deviceUUID?: string;
}

interface ScheduleRule {
  day: number;
  fromTime: string;
  key: string;
  toTime: string;
}

export const overrideFormErrorStyle: React.CSSProperties = {
  whiteSpace: 'nowrap',
};

const FieldsContainer = styled.div<{ isAddForm: boolean }>`
  position: relative;
  margin-left: ${(props) => (props.isAddForm ? '11' : '37')}px;
  @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${tabletMaxWidthPx}) {
    padding-left: 0;
    margin-left: 0;
  }
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    padding-left: 0;
    margin-left: 0;
  }
`;

type EquipmentTriggerFormProps = {
  typesCount: number;
  formType: 'add' | 'edit';
};
const EquipmentTriggerForm = styled.div<EquipmentTriggerFormProps>`
  display: grid;
  grid-template-areas: ${({ typesCount, formType }) => {
    switch (true) {
      case formType === 'edit':
        return `"minutes modal"`;
      case typesCount === 1: // * no humidity so no  alert type
        return `"comparator minutes modal"`;
      default:
        return `"comparator minutes type modal"`;
    }
  }};
  grid-template-columns: ${({ typesCount, formType }) => {
    switch (true) {
      case formType === 'edit':
        return `157px min-content`;
      case typesCount === 1: // * no humidity so no  alert type
        return `145px 157px min-content`;
      default:
        return `145px 157px auto min-content`;
    }
  }};
  column-gap: 20px;
  margin: 0;
  @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${tabletMaxWidthPx}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    grid-template-areas:
      'comparator'
      'minutes'
      'type'
      'modal';
    grid-template-columns: 1fr;
  }
`;

const TriggerForm = styled.div`
  display: grid;
  grid-template-areas: 'comparator temperature minutes';
  grid-template-columns: 283px 157px 0;
  column-gap: 10px;
  margin: 0;
  @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${tabletMaxWidthPx}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    grid-template-areas:
      'comparator'
      'temperature'
      'minutes';
    grid-template-columns: 1fr;
  }
`;

const ComparatorField = styled(Form.Field)`
  grid-area: comparator;
  min-width: 0;
`;

const TemperatureField = styled.div`
  grid-area: temperature;
`;

const TypeField = styled.div`
  grid-area: type;
`;

const ModalField = styled.div`
  grid-area: modal;
  padding-top: calc(1em + 10px);
`;

export const FlexedContainer = styled.div`
  display: flex;
  align-items: center;

  .field {
    flex-grow: 1;
  }
`;

const MinutesField = styled.div`
  grid-area: minutes;

  .flex {
    display: flex;
    align-items: center;
  }

  .flex > div {
    flex-grow: 1;
    margin-bottom: 1em;
  }
`;

const AlertFormLabel = styled.div`
  margin-bottom: 6px;
  color: ${darkViolet80};
  font-weight: bold;
`;

export const AlertUnitLabel = styled.strong`
  padding-left: 10px;
  color: ${darkViolet80};
`;

type AlertConfigFormButtonContainerProps = {
  isRecommendedAlert: boolean;
};
const AlertConfigFormButtonContainer = styled.div<
  AlertConfigFormButtonContainerProps
>`
  padding: 20px 0;
  text-align: right;
  position: relative;
  right: ${(props) => (props.isRecommendedAlert ? '-30' : '0')}px;
  @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${tabletMaxWidthPx}) {
    margin-right: 0;
    right: 0;
  }
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    margin-right: 0;
    right: 0;
  }
`;

const AlertDeviceLabel = styled.label`
  &&&& {
    display: flex;
    white-space: pre;
    grid-template-columns: 40px auto 1fr;
    width: 90%;
    letter-spacing: 0;
    margin-bottom: 6px;
    font-weight: bold;
  }
`;

const SensorName = styled.div`
  width: auto;
  height: 16px;
`;

const IsText = styled.span`
  width: 30px;
`;

const AlertConfigFormContainer = styled.div`
  .action-align {
    align-self: flex-end;
  }

  @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${tabletMaxWidthPx}) {
    padding-left: 50px;
  }

  @media only screen and (max-width: ${mobileMaxWidth}px) {
    ${AlertConfigFormButtonContainer} {
      display: grid;
      row-gap: 5px;
      button {
        margin: 0 0 5px;
      }
    }
    && {
      input.search {
        display: block;
      }
    }
  }

  @media only screen and (max-width: ${w414}px) {
    ${TriggerForm} {
      grid-template-areas:
        'comparator'
        'temperature'
        'minutes';
      grid-template-columns: 1fr;
    }

    ${AlertUnitLabel} {
      flex: unset;
      width: 20%;
    }
  }

  @media only screen and (max-width: ${w540}px) and (min-width: ${w415}px) {
    ${TriggerForm} {
      grid-template-areas:
        'comparator comparator'
        'temperature minutes';
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const BellButtonContainer = styled.div`
  text-align: left;
  margin: 0 0 15px 0;
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    display: flex;
  }
`;

type NotificationContainerProps = {
  hasError?: boolean;
  isEquipmentEnabled?: boolean;
};
const NotificationContainer = styled.div<NotificationContainerProps>`
  display: grid;
  grid-template-columns: ${({ isEquipmentEnabled }) =>
    isEquipmentEnabled ? `1fr` : `1fr 1fr`};
  align-items: center;
  margin-top: ${({ hasError }) => (hasError ? '15' : '0')}px;

  @media only screen and (max-width: ${mobileMaxWidth}px) {
    display: block;
  }
  @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${tabletMaxWidthPx}) {
    display: grid;
    grid-template-columns: ${({ isEquipmentEnabled }) =>
      isEquipmentEnabled ? `auto` : `auto 1fr`};
  }
`;

const NotificationInput = styled.div`
  min-width: 603px;
  .input-error {
    position: absolute;
  }
  .label {
    word-break: break-all;
  }
  .item {
    word-break: break-all;
  }
  @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${tabletMaxWidthPx}) {
    min-width: 100%;
  }
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    min-width: auto;
  }
`;

const DeleteAlertButton = styled.div`
  text-align: left;
  position: absolute;
  left: -30px;
  &&&& {
    .button {
      width: 43px;
      height: 43px;
      font-size: 17px;
    }
  }
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    &&&& {
      position: relative;
      left: 0;
      .button {
        font-size: 17px;
      }
    }
  }
`;

const NotificationButton = styled.div`
  margin-left: 0px;
  margin-top: 10px;
`;

export const getTriggerValue = ({
  triggerValue,
  field,
}: AlertConfigFormFields): number => {
  switch (field) {
    case 'battery':
      return DEFAULT_BATTERY_VALUE;
    case 'temperature':
    default:
      return Number(triggerValue);
  }
};

export const getEquipmentTriggerValue = (
  { triggerValue, triggerComparator, field }: AlertConfigFormFields,
  equipmentData: EquipmentPayloadData,
): number => {
  switch (field) {
    case 'battery':
      return DEFAULT_BATTERY_VALUE;
    case 'humidity':
      if (
        triggerComparator === '>' &&
        isValidNumber(equipmentData?.meta?.humidityMaxThreshold)
      ) {
        return Number(equipmentData?.meta.humidityMaxThreshold);
      } else if (
        triggerComparator === '<' &&
        isValidNumber(equipmentData?.meta?.humidityMinThreshold)
      ) {
        return Number(equipmentData?.meta.humidityMinThreshold);
      }
    case 'temperature':
    default:
      if (
        triggerComparator === '>' &&
        isValidNumber(equipmentData?.meta?.temperatureMaxThreshold)
      ) {
        return Number(equipmentData?.meta.temperatureMaxThreshold);
      } else if (
        triggerComparator === '<' &&
        isValidNumber(equipmentData?.meta?.temperatureMinThreshold)
      ) {
        return Number(equipmentData?.meta.temperatureMinThreshold);
      }

      return Number(triggerValue);
  }
};

export const generateDefaultAlertFormFieldValues = (
  type: AlertType = 'temperature',
) => ({
  recipients: [],
  triggerValue: undefined,
  field: type,
  timeframe: type === 'battery' ? 240 : 30,
  triggerComparator: type === 'battery' ? '<' : '>',
});

export const AlertConfigForm: FunctionComponent<AlertConfigFormProps> = (
  props,
) => {
  const {
    deviceUUID: deviceUUIDProp,
    hasActiveAlerts,
    deleteButton,
    formType = 'add',
    isRecommendedAlert = false,
    equipmentUUID,
    defaultValues,
  } = props;
  const { user } = useContext(AccountContext);
  const { store } = useContext(StoreContext);
  const { isQueryInProgress } = store.pages;
  const { notifConfigs, devices, equipment } = store.entities;
  const { preferredTempUnit = 'f' } = user || {};
  const formMethods = useFormContext<NormalAlertModel>();
  const { formState } = formMethods;
  const { isSubmitting } = formState;
  const alertConfig = formMethods.watch();
  const notifMethods = formMethods.watch('methods');
  const type = formMethods.watch('field') || 'temperature';
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [sensorName, setSensorName] = useState<string>('');
  const [rules, setRules] = useState(get(alertConfig, 'schedule.rules', []));
  const [timezone, setTimezone] = useState<string>(
    get(alertConfig, 'schedule.settings.timezone', moment.tz.guess(true)),
  );
  const [showForm, setShowForm] = useState(false);
  const [isSavedByScheduler, setIsSavedByScheduler] = useState<boolean>(false);
  const deviceUUID: string | undefined = alertConfig?.deviceUUID
    ? alertConfig.deviceUUID
    : deviceUUIDProp;
  const [isAddingCustomNotif, setIsAddingCustomNotif] = useState(false);
  const [notifMethodDefault, setNotifMethodDefault] = useState<
    string[] | undefined
  >(user?.notificationMethods);

  const { isEquipmentEnabled } = useFeatures();
  const [defaultComparator] = useState('>');
  let defaultAlertType =
    formType === 'edit' ? alertConfig.field : typeOptionsValues.temperature;

  const equipmentData = isEquipmentEnabled
    ? (equipment.byUUID[equipmentUUID as string] as EquipmentPayloadData)
    : null;

  useEffect(() => {
    // for now we only allow 1 notif config onto an alert config
    // and need to set up some field values to populate correctly
    if (
      !alertConfig ||
      !alertConfig.notifConfigs ||
      !alertConfig.notifConfigs.length ||
      !notifConfigs.byUUID[alertConfig.notifConfigs[0]]
    ) {
      setShowForm(true);
      return;
    }

    setShowForm(true);
  }, []);

  useEffect(() => {
    if (!deviceUUID) {
      setSensorName(DEFAULT_SENSOR_NAME);
      return;
    }
    let name: string;
    if (isEquipmentEnabled) {
      name = equipment.byUUID[equipmentUUID as string].name;
    } else {
      name = devices.byUUID[deviceUUID].name;
    }
    setSensorName(name);
  }, []);

  useEffect(() => {
    hasActiveAlerts(!!rules.length);
  }, [rules.length]);

  useEffect(() => {
    if (formState.isValid && isSavedByScheduler) {
      setIsSubmitDisabled(false);
    } else if (!isFormAcceptable(formState) || !isEmpty(formMethods.errors)) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [formState.isValid, formState.dirty, isSavedByScheduler]);

  const checkHasMethods = () => {
    setIsAddingCustomNotif(true);

    // get notif methods from recipients if possible
    const recipients = formMethods.watch('recipients');
    if (recipients.length > 0) {
      const allMethods: string[] = [];
      recipients.map((userUUID: string) => {
        allMethods.push(
          ...(allUsers.byUUID[userUUID].notificationMethods || []),
        );
      });
      const filteredMethods = [...new Set(allMethods)];

      setNotifMethodDefault(filteredMethods as NotificationMethod[]);
      setIsSubmitDisabled(false);
    } else {
      setNotifMethodDefault(user?.notificationMethods);
    }
  };

  // check above
  function saveScheduleHandler(
    scheduleRules: ScheduleRule[],
    timezoneSchedule: string,
  ) {
    const noKeyScheduleRules = scheduleRules.map((scheduleRule: ScheduleRule) =>
      omit(scheduleRule, ['key']),
    );
    setTimezone(timezoneSchedule);
    setRules(noKeyScheduleRules);
    setIsSavedByScheduler(true);
    return;
  }

  const {
    users: { allUsers },
  } = store.pages;

  const userOptions: DropdownItemProps[] = [];

  allUsers.all.forEach((userUUID: string) => {
    const _user = allUsers.byUUID[userUUID];

    if (!_user.signedUpDate) {
      return;
    }

    userOptions.push({
      key: userUUID,
      text: `${_user.firstName} ${_user.lastName}`,
      value: userUUID,
    });
  });

  const comparatorOptions = [{ text: 'above', value: '>' }];

  if (
    !isEquipmentEnabled ||
    (formType === 'edit' && alertConfig.triggerComparator === '<')
  ) {
    comparatorOptions.push({ text: 'below', value: '<' });
  } else if (
    (isValidNumber(equipmentData?.meta.temperatureMinThreshold) ||
      isValidNumber(equipmentData?.meta.humidityMinThreshold)) &&
    formType === 'add'
  ) {
    comparatorOptions.push({ text: 'above or below', value: '<>' });
  }

  const typeOptions: { text: string; value: string; disabled?: boolean }[] = [
    { text: 'temperature', value: typeOptionsValues.temperature },
  ];

  if (
    isValidNumber(equipmentData?.meta.humidityMaxThreshold) ||
    isValidNumber(equipmentData?.meta.humidityMinThreshold)
  ) {
    if (formType === 'add') {
      typeOptions.unshift({
        text: 'temperature and humidity',
        value: typeOptionsValues.temperatureAndHumidity,
      });
    }

    typeOptions.push({
      text: 'humidity',
      value: typeOptionsValues.humidity,
      disabled:
        formMethods.watch('triggerComparator') === '>' &&
        !isValidNumber(equipmentData?.meta.humidityMaxThreshold),
    });
  }

  if (
    equipmentData?.meta.humidityMaxThreshold ||
    equipmentData?.meta.humidityMinThreshold
  ) {
    defaultAlertType = typeOptionsValues.temperatureAndHumidity;
  }

  const timeframeOptions = timeframes.map((value) => {
    return {
      key: value,
      text: `${value}`,
      value,
    };
  });

  const doAlertConfigAdd = async (
    alertConfigFormVal: AlertNotifConfigPayload,
    isAddAnother = false,
  ) => {
    await props.onSubmit(alertConfigFormVal, isAddAnother);

    const alertRecipients = alertConfigFormVal.recipients.map((uuid) => {
      const recipient = allUsers.byUUID[uuid];
      return `${recipient.firstName} ${recipient.lastName}`;
    });

    const mixpanelSensorName = alertConfigFormVal.deviceUUID
      ? isEquipmentEnabled
        ? store.entities.equipment.byUUID[alertConfigFormVal.deviceUUID].name
        : store.entities.devices.byUUID[alertConfigFormVal.deviceUUID].name
      : '';

    let alertAddedEventProps = {
      alert_type: type === 'battery' ? 'connection_notifications' : type,
      trigger_value: alertConfigFormVal.triggerValue,
      timeframe: alertConfigFormVal.timeframe,
      alert_recipients: alertRecipients,
      alert_recipients_by_uuid: alertConfigFormVal.recipients,
      delivery_methods: alertConfigFormVal.methods,
      schedule_enabled: !!alertConfigFormVal?.schedule?.rules?.length
        ? 'Yes'
        : 'No',
      sensor_name: mixpanelSensorName,
      recommended_alert: isRecommendedAlert ? 'Yes' : 'No',
      comparator:
        alertConfigFormVal.triggerComparator === '>' ? 'Above' : 'Below',
    };

    alertAddedEventProps = {
      ...alertAddedEventProps,
      trigger_value: alertConfigFormVal.triggerValue,
    };
    mixpanel.track('Alert Added', {
      ...alertAddedEventProps,
    });
  };

  const handleSubmit = async (
    data: NormalAlertModel,
    isAddAnother: boolean,
  ) => {
    let isNormalAlertConfigAdd = true;
    setIsSubmitDisabled(true);
    try {
      const alertConfigFormVal: AlertNotifConfigPayload = {
        ...generateDefaultAlertFormFieldValues(data.field || defaultValues.field),
        ...data,
        deviceUUID: data.deviceUUID ? data.deviceUUID : deviceUUID,
        methods: data.methods || defaultValues.methods || [],
        schedule: {
          rules,
          settings: {
            timezone,
          },
        },
      };

      /**
       * Equipment based section
       * Check for multiple combinations
       * to trigger multiple requests
       */
      if (isEquipmentEnabled) {
        alertConfigFormVal.equipmentUUID = equipmentUUID;
        if (data.alertType === 'both' && data.triggerComparator === '<>') {
          isNormalAlertConfigAdd = false;
          await createAboveBelowTempHumidAlerts(
            doAlertConfigAdd,
            alertConfigFormVal,
            equipmentData as EquipmentPayloadData,
            isAddAnother,
          );
          return;
        }

        if (data.triggerComparator === '<>') {
          isNormalAlertConfigAdd = false;
          await createAboveBelowAlerts(
            doAlertConfigAdd,
            alertConfigFormVal,
            equipmentData as EquipmentPayloadData,
            isAddAnother,
            data.alertType,
          );
          return;
        }

        if (data.alertType === 'both') {
          isNormalAlertConfigAdd = false;
          await createTempHumidAlerts(
            doAlertConfigAdd,
            alertConfigFormVal,
            data.triggerComparator,
            equipmentData as EquipmentPayloadData,
            isAddAnother,
          );
          return;
        }

        if (isNormalAlertConfigAdd) {
          await doAlertConfigAdd(
            {
              ...alertConfigFormVal,
              // Fallback to field for connection alerts since alertType is null during this
              field: data.alertType ?? alertConfigFormVal.field,
              triggerValue: determineTriggerValue(
                equipmentData,
                data.triggerComparator,
                data.alertType,
              ),
            },
            isAddAnother,
          );
        }
      } else {
        await doAlertConfigAdd(alertConfigFormVal);
      }
    } catch (e) {
      setIsSubmitDisabled(false);
    }
  };

  useEffect(() => {
    if (notifMethods?.length === 0) {
      setIsAddingCustomNotif(false);
    }
  }, [notifMethods]);

  useEffect(() => {
    if (
      alertConfig &&
      alertConfig.notifConfigs &&
      alertConfig.notifConfigs.length &&
      isEmpty(notifConfigs.byUUID[alertConfig.notifConfigs[0]].methods)
    ) {
      setIsAddingCustomNotif(false);
      setNotifMethodDefault(user?.notificationMethods);
      return;
    }

    if (
      alertConfig &&
      alertConfig.notifConfigs &&
      alertConfig.notifConfigs.length &&
      !isEmpty(notifConfigs.byUUID[alertConfig.notifConfigs[0]].methods)
    ) {
      setIsAddingCustomNotif(true);
      setNotifMethodDefault(
        notifConfigs.byUUID[alertConfig.notifConfigs[0]].methods,
      );
      return;
    }
  }, [alertConfig.notifConfigs]);

  return (
    <AlertConfigFormContainer>
      {showForm && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Form className="alert-config-form" id="alert-form">
          {/* 
          //FIXME: register method returns void (not a valid object), this is not working
          <input hidden {...formMethods.register('field') ?? {}} />
          <input hidden {...formMethods.register('isSmartAlert')} />
          <input hidden {...formMethods.register('deviceUUID')} /> 
          */}
          {isRecommendedAlert ||
            (isEquipmentEnabled && formType === 'edit' && (
              // * still need to register the fields even if they are hidden if recommended alert
              // * register hidden fields for editing alert config
              <>
                {/*
                //FIXME: register method returns void (not a valid object), this is not working
                <input hidden {...formMethods.register('triggerComparator')} />
                <input hidden {...formMethods.register('triggerValue')} />
                <input hidden {...formMethods.register('timeframe')} /> 
                */}
              </>
            ))}
          <FieldsContainer isAddForm={formType === 'add'}>
            {type !== 'battery' && !isRecommendedAlert ? (
              <>
                {isEquipmentEnabled ? (
                  <EquipmentTriggerForm
                    typesCount={typeOptions.length}
                    formType={formType}
                  >
                    {formType === 'add' && (
                      <ComparatorField>
                        <AlertDeviceLabel>
                          <label>When: </label>
                        </AlertDeviceLabel>

                        <div>
                          <FormDropdown
                            name="triggerComparator"
                            options={comparatorOptions}
                            selection
                            fluid
                            required
                            showrequired={false}
                            search
                            disabled={
                              isSubmitting || comparatorOptions.length === 1
                            }
                            defaultValue={defaultComparator}
                          />
                        </div>
                      </ComparatorField>
                    )}

                    <MinutesField>
                      <div className="flex">
                        <div>
                          <AlertFormLabel>For:</AlertFormLabel>
                          <FormDropdown
                            name="timeframe"
                            options={timeframeOptions}
                            required
                            showrequired={false}
                            selection
                            fluid
                            disabled={isSubmitting}
                            search
                            validation={{
                              required: (val) => typeof val === 'number',
                            }}
                          />
                        </div>
                        <AlertUnitLabel>
                          <Media lessThan={'w414'}>min.</Media>
                          <Media greaterThanOrEqual={'w415'}>minutes</Media>
                        </AlertUnitLabel>
                      </div>
                    </MinutesField>

                    {typeOptions.length !== 1 && formType === 'add' && (
                      <TypeField>
                        <AlertDeviceLabel>
                          <label>Alert types: </label>
                        </AlertDeviceLabel>

                        <FormDropdown
                          name="alertType"
                          options={typeOptions}
                          selection
                          fluid
                          required
                          showrequired={false}
                          search
                          disabled={isSubmitting}
                          defaultValue={defaultAlertType}
                        />
                      </TypeField>
                    )}

                    <ModalField>
                      <DesktopAndTablet>
                        <AlertSchedulerModal
                          rules={rules}
                          timezone={timezone}
                          onSubmit={saveScheduleHandler}
                          isDisabled={isSubmitting}
                        />
                      </DesktopAndTablet>
                      {/* Alert sleep schedule block */}
                      {/* <Button
                        className="equipment-alert-config-form smart-alert__button"
                        circular
                        floated="right"
                        icon
                        type="button"
                      >
                        <Icon
                          className={`icon-moon-sleep smart-alert__button__icon`}
                          size="large"
                        />
                      </Button> */}
                    </ModalField>
                  </EquipmentTriggerForm>
                ) : (
                  <TriggerForm>
                    <ComparatorField>
                      <AlertDeviceLabel>
                        <label>When </label>
                        <SensorName className="ellipse-text alert-config-form__sensor-name">
                          {sensorName}
                        </SensorName>
                        <IsText> is: </IsText>
                      </AlertDeviceLabel>

                      <FormDropdown
                        name="triggerComparator"
                        options={comparatorOptions}
                        selection
                        fluid
                        required
                        showrequired={false}
                        search
                        disabled={isSubmitting}
                      />
                    </ComparatorField>

                    <TemperatureField>
                      <FlexedContainer>
                        <FormInput
                          name="triggerValue"
                          label={`${type.charAt(0).toUpperCase()}${type.slice(
                            1,
                          )}:`}
                          required
                          customErrors={{
                            required: `A ${type} is required.`,
                          }}
                          placeholder="0"
                          fluid
                          type="number"
                          step="0.1"
                          customStyle={overrideFormErrorStyle}
                          onChange={
                            type === 'temperature' ? roundNumber : validHumidity
                          }
                          min={type === 'humidity' ? '0' : ''}
                          disabled={isSubmitting}
                          showrequired={false}
                          defaultValue={undefined}
                        />
                        <AlertUnitLabel>
                          {type === 'temperature' ? (
                            <>&deg;{preferredTempUnit.toUpperCase()}</>
                          ) : (
                            <>&#37;</>
                          )}
                        </AlertUnitLabel>
                      </FlexedContainer>
                    </TemperatureField>
                    <MinutesField>
                      <div className="flex">
                        <div>
                          <AlertFormLabel>For:</AlertFormLabel>
                          <FormDropdown
                            name="timeframe"
                            options={timeframeOptions}
                            required
                            showrequired={false}
                            selection
                            fluid
                            disabled={isSubmitting}
                            search
                            validation={{
                              required: (val) => typeof val === 'number',
                            }}
                          />
                        </div>
                        <AlertUnitLabel>
                          <Media lessThan={'w414'}>min.</Media>
                          <Media greaterThanOrEqual={'w415'}>minutes</Media>
                        </AlertUnitLabel>
                      </div>
                    </MinutesField>
                  </TriggerForm>
                )}
              </>
            ) : (
              <></>
            )}
            <NotificationContainer isEquipmentEnabled>
              <NotificationInput>
                <FormDropdown
                  label="Then notify:"
                  required
                  showrequired={false}
                  fluid
                  multiple
                  search
                  selection
                  placeholder="Select one or more recipients"
                  options={userOptions}
                  name="recipients"
                  className="dropdown-field"
                  size="big"
                  customErrors={{
                    required: 'At least one user is required.',
                  }}
                  disabled={isSubmitting}
                  defaultValue={[]}
                />
              </NotificationInput>
              {!isEquipmentEnabled && (
                <DesktopAndTablet>
                  <NotificationButton>
                    <AlertSchedulerModal
                      rules={rules}
                      timezone={timezone}
                      onSubmit={saveScheduleHandler}
                      isDisabled={isSubmitting}
                    />
                  </NotificationButton>
                </DesktopAndTablet>
              )}
            </NotificationContainer>

            {!isAddingCustomNotif && (
              <AddTextButton
                hasInfo={true}
                infoHeader="Alert notification method"
                infoText="Alerts will be sent via notification methods set in user profile. Select to change notification methods for only this alert."
                margin="16px 0 0 0"
                padding={
                  formMethods.errors.recipients ? '20px 0px 12px' : '0px'
                }
                onClick={() => {
                  checkHasMethods();
                }}
                label="Customize alert notification method"
                color={ThermaSecondaryAction400}
                hoverColor={ThermaSecondaryAction400}
              />
            )}
            {isAddingCustomNotif && (
              <NotificationContainer hasError={!!formMethods.errors.recipients}>
                <NotificationInput>
                  <FormDropdown
                    label="Customize alert notification method:"
                    description="Set additional notification methods for only this alert"
                    showrequired={false}
                    required
                    fluid
                    search
                    selection
                    placeholder="Select one or more"
                    options={NotifMethodOptions}
                    multiple
                    name="methods"
                    className="dropdown-field"
                    size="big"
                    disabled={isSubmitting}
                    defaultValue={notifMethodDefault}
                  />
                </NotificationInput>
                <DesktopAndTablet>
                  <NotificationButton>
                    <FormCloseButton
                      style={{
                        position: 'relative',
                        marginLeft: '20px',
                        marginTop: '25px',
                      }}
                      onClick={() => {
                        setIsAddingCustomNotif(false);
                        if (formMethods.watch('recipients').length > 0) {
                          setIsSubmitDisabled(false);
                        } else {
                          setIsSubmitDisabled(true);
                        }
                        setNotifMethodDefault(user?.notificationMethods);
                      }}
                    />
                  </NotificationButton>
                </DesktopAndTablet>
              </NotificationContainer>
            )}
            <FlexPaddedContent size="0">
              <PillContent>
                <AlertConfigFormButtonContainer
                  isRecommendedAlert={isRecommendedAlert}
                >
                  {!isEquipmentEnabled && (
                    <MobileOnly>
                      <BellButtonContainer>
                        <AlertSchedulerModal
                          rules={rules}
                          timezone={timezone}
                          onSubmit={saveScheduleHandler}
                        />
                        <DeleteAlertButton>{deleteButton}</DeleteAlertButton>
                      </BellButtonContainer>
                    </MobileOnly>
                  )}
                  <DesktopAndTablet>
                    <DeleteAlertButton>{deleteButton}</DeleteAlertButton>
                  </DesktopAndTablet>

                  {type !== 'battery' && props.onCancel && (
                    <Button
                      className="alert-config-form__cancel-button"
                      secondary
                      content="Cancel"
                      type="button"
                      onClick={props.onCancel}
                      disabled={
                        isQueryInProgress['alertConfig:add'] ||
                        isQueryInProgress['alertConfig:edit']
                      }
                    />
                  )}
                  {type !== 'battery' &&
                    formType === 'add' &&
                    isEquipmentEnabled && (
                      <Button
                        className="alert-config-form__save-button"
                        secondary
                        disabled={
                          isSubmitDisabled ||
                          isQueryInProgress['alertConfig:add'] ||
                          isQueryInProgress['alertConfig:edit']
                        }
                        loading={
                          isQueryInProgress['alertConfig:add'] ||
                          isQueryInProgress['alertConfig:edit']
                        }
                        id="save-alert-add-another"
                        content="Save and add another"
                        type="button"
                        onClick={formMethods.handleSubmit((data) =>
                          handleSubmit(data, true),
                        )}
                      />
                    )}
                  <Button
                    className="alert-config-form__save-button"
                    primary
                    disabled={
                      isSubmitDisabled ||
                      isQueryInProgress['alertConfig:add'] ||
                      isQueryInProgress['alertConfig:edit']
                    }
                    loading={
                      isQueryInProgress['alertConfig:add'] ||
                      isQueryInProgress['alertConfig:edit']
                    }
                    id="save-alert"
                    content="Save"
                    type="submit"
                    onClick={formMethods.handleSubmit((data) =>
                      handleSubmit(data, false),
                    )}
                  />
                </AlertConfigFormButtonContainer>
              </PillContent>
            </FlexPaddedContent>
          </FieldsContainer>
        </Form>
      )}
    </AlertConfigFormContainer>
  );
};

export function roundNumber(e: string) {
  const [base, decimals] = e.split('.');
  if (decimals) {
    return `${base}.${decimals.slice(0, 1)}`;
  }
  return e;
}

export function validHumidity(e: string) {
  // Remove extra decimals if any
  let val = e.replace(/(\.\d\d?)\d+/, '$1');

  // Remove extra decimal points, keeping only the first one
  const splitVal = val.split('.');
  if (splitVal.length > 2) {
    val = splitVal.shift() + '.' + splitVal.join('');
  }

  const humidityNum = parseFloat(val);

  // Validate the input value
  if (humidityNum < 0) {
    return (-1 * humidityNum).toFixed(1);
  } else if (humidityNum > 100) {
    return '100';
  }

  // Return the input value as is, maintaining the decimal point
  return val;
}

import { CancelToken } from 'axios';

import { API_VERSION } from '../constants';
import { request } from '../contexts';
import { getItem, setItem } from '../hooks';
import { BaseEntity, DashboardBrowseParams, ResponseDataNumber } from './index';

export interface AlertNotifRecipient {
  uuid: string;
  lastName: string;
  firstName: string;
}

export interface AlertNotifMetaData {
  deviceName?: string;
  field?: string;
  hvacSetpointBuffer?: number;
  lastSensorValue?: number;
  locationName?: string;
  rateTempChange?: {
    c: number;
    f: number;
    direction: string;
  };
  rawSummary?: string;
  reason: string;
  recipients: AlertNotifRecipient[];
  summary: string;
  timeframe?: number;
  triggerComparator?: string;
  triggerValue?: number;
}
export interface AlertNotif {
  activeMins: number | null;
  alertConfigUUID: string;
  companyUUID: string;
  deviceUUID: string;
  endedAt?: Date | string;
  energyDeviceUUID?: string;
  locationUUID: string;
  metadata: AlertNotifMetaData;
  notifConfigUUID: string;
}

export interface AlertNotifBrowseParams {
  endDate?: string;
  limit?: number;
  page?: number;
  startDate?: string;
  deviceUUID?: string[];
  field?: string;
  reason?: string;
  activeOnly?: boolean;
}

export interface AlertNotifModel extends BaseEntity, AlertNotif {}

export const browseActiveAlertGroupedByDevice_CACHE =
  'browseActiveAlertGroupedByDevice';

export class AlertNotifService {
  public static async browse(
    params: AlertNotifBrowseParams,
    cancelToken?: CancelToken,
  ) {
    const result = await request.post(
      `/api/${API_VERSION}/alert-notif/browse`,
      {
        cancelToken,
        ...params,
      },
    );

    return result.data;
  }

  public static async readTimeAlertFree(
    params: DashboardBrowseParams,
  ): Promise<ResponseDataNumber> {
    const { data: result } = await request.post(
      `/api/${API_VERSION}/alert-notif/time-alert-free`,
      {
        ...params,
      },
    );
    return result.data;
  }

  public static async readMetrics() {
    const { data: result } = await request.get(
      `/api/${API_VERSION}/alert-notif/metrics`,
    );
    return result.data;
  }

  public static async browseActiveAlert() {
    const { data: result } = await request.get(
      `/api/${API_VERSION}/alert-notif/active-notif`,
    );

    return result.data;
  }

  public static async browseActiveEnergyAlert(params: {
    energyDeviceUUID: string;
    limit: number;
    field: string;
  }) {
    const { data: result } = await request.get(
      `/api/${API_VERSION}/energy-device-notif/latest`,
      {
        params,
      },
    );
    return result.data || {};
  }

  public static async browseActiveAlertGroupedByDevice(
    deviceUUID: string[] = [],
  ) {
    try {
      if (deviceUUID.length <= 0) {
        return [];
      }

      if (localStorage.hasOwnProperty(browseActiveAlertGroupedByDevice_CACHE)) {
        return JSON.parse(getItem(browseActiveAlertGroupedByDevice_CACHE, []));
      }

      setItem(browseActiveAlertGroupedByDevice_CACHE, JSON.stringify([]));

      const { data: result } = await request.post(
        `/api/${API_VERSION}/alert-notif/device-has-active-alert`,
        {
          deviceUUID,
        },
      );
      setItem(
        browseActiveAlertGroupedByDevice_CACHE,
        JSON.stringify(result.data),
      );

      return result.data;
    } catch (e) {
      return [];
    }
  }

  public static async markAsHealthyByAlertConfig(alertConfigUUID: string) {
    const { data: result } = await request.post(
      `/api/${API_VERSION}/alert-notif/healthy/alert-config/${alertConfigUUID}`,
    );
    return result.data;
  }

  public static async markAsHealthyByDevice(deviceUUID: string) {
    const { data: result } = await request.post(
      `/api/${API_VERSION}/alert-notif/healthy/device/${deviceUUID}`,
    );
    return result.data;
  }
}

import { greyMystic, PaddedContent } from '@coinspect/ui';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Button, Icon } from 'semantic-ui-react';
import { mobileMaxWidth, tabletMaxWidth } from 'src/constants';
import styled from 'styled-components';

import { AccountContext } from '../../../contexts/account-context';
import {
  AlertNotifConfigPayload,
  NormalAlertModel,
  useAddMoreSensorModal,
  useAlertConfigService,
  useDialogs,
  useFeatures,
} from '../../../hooks';
import { AlertNotifService } from '../../../services';
import { StoreContext } from '../../../store';
import { PowerButton } from '../../buttons';
import { EquipmentPayloadData } from '../../equipment/equipment-service';
import { useDevicePage } from '../../pages/use-device-page';
import { Tooltip } from '../../tooltip';
import { AlertListHeading } from '../alert-list';
import { AlertConfigForm, getEquipmentTriggerValue } from './alert-config-form';

interface AlertLineProps {
  dragEnabled: boolean;
}

const AlertLineItem = styled.div<AlertLineProps>`
  &&&& {
    border-bottom: 1px solid ${greyMystic};
    position: relative;
    box-shadow: none;
    ${({ dragEnabled }) =>
      dragEnabled &&
      `
      &:hover {
        z-index: 3;
        border-radius: 4px;
        box-shadow: 0px 1px 5px rgba(0,0,0,0.2);
      }
    `}

    background: white;
    @media only screen and (max-width: ${tabletMaxWidth}px) {
      padding-left: 44px;
    }
  }
`;

const AlertConfigFormContainer = styled(PaddedContent)`
  @media only screen and (min-width: ${tabletMaxWidth}px) and (max-width: ${tabletMaxWidth}px) {
    padding: 20px 40px 20px 0px;
  }
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    padding: 20px 40px 20px 60px;
  }
`;

export interface AlertConfigLineItemProps {
  alertConfig: NormalAlertModel;
  deviceUUID: string;
  index: number;
  equipmentUUID?: string;
}

export const AlertConfigLineItem: FunctionComponent<AlertConfigLineItemProps> = (
  props,
) => {
  const { user } = useContext(AccountContext);
  const { alertConfig, index, deviceUUID, equipmentUUID } = props;
  const {
    deleteAlertConfig,
    addAlertConfig,
    setAlertStatus,
    hasRecommendedAlert,
  } = useAlertConfigService();
  const { addActiveForm, removeActiveForm, hasActiveForm } = useDevicePage();
  const { isDragAllowed, isEquipmentEnabled } = useFeatures();
  const { store } = useContext(StoreContext);
  const { openDeleteDialog, closeDialog } = useDialogs();
  const { notifConfigs, devices, equipment } = store.entities;
  const { isQueryInProgress } = store.pages;
  const { isReorderInProgress } = store.pages.sensors;
  const [isEditing, setIsEditing] = useState(false);
  let deviceName: string;
  let isSmartAlert: boolean;
  let status: string | undefined;
  if (isEquipmentEnabled) {
    // * deviceUUID is equipmentUUID on equipment
    deviceName = equipment.byUUID[equipmentUUID as string].name;
    status = (equipment.byUUID[equipmentUUID as string] as EquipmentPayloadData)
      .meta.status;
    isSmartAlert = false; // * not migrated to equipment based
  } else {
    deviceName = devices.byUUID[deviceUUID].name;
    status = devices.byUUID[deviceUUID].status;
    isSmartAlert = devices.byUUID[deviceUUID].isSmartAlert as boolean;
  }
  const selectNotifConfig = (uuid: string) => notifConfigs.byUUID[uuid];
  const formMethods = useForm<AlertNotifConfigPayload>({ mode: 'onChange' });
  const formValue = formMethods.watch();
  const [hasActiveAlert, setHasActiveAlert] = useState<boolean>(false);
  const [isAlertActive, setAlertActive] = useState(
    alertConfig.status === 'active',
  );
  const { editAlertConfig } = useAlertConfigService();
  const { showAddMoreSensorModal } = useAddMoreSensorModal();

  function isDeviceActive(): boolean {
    /**
     * * Always return true if on equipment based
     * * Leaving the device logic in tact for backwards compatibility
     */
    if (isEquipmentEnabled) return true;
    return status === 'active';
  }

  function isAlertSnoozed(): boolean {
    if (!alertConfig.snoozeEndAt) {
      return false;
    }

    return new Date().getTime() < new Date(alertConfig.snoozeEndAt).getTime();
  }

  function hideEdit(): void {
    setIsEditing(false);
    removeActiveForm();
  }

  function generateDefaultValues(
    isDuplicate?: boolean,
  ): AlertNotifConfigPayload {
    const propsAlertConfigNotifConfigs = props.alertConfig.notifConfigs || [];
    const notifConfigUUID = propsAlertConfigNotifConfigs[0];

    let recipients: string[] = [];
    let methods: string[] = [];

    if (notifConfigUUID) {
      const details = selectNotifConfig(notifConfigUUID);
      if (details.recipients) recipients = details.recipients;
      if (isDuplicate) {
        methods = details.methods;
      }
    }

    if (!isEmpty(methods)) {
      return {
        ...alertConfig,
        methods,
        recipients,
        isSmartAlert,
      };
    }

    return {
      ...alertConfig,
      recipients,
      isSmartAlert,
    };
  }

  const resetConfig = () => formMethods.reset(generateDefaultValues());

  useEffect(() => {
    resetConfig();
    setHasActiveAlert(!!get(alertConfig, 'schedule.rules.length', 0));
  }, [alertConfig, notifConfigs]);

  async function handleAlertConfigEdit(
    data: AlertNotifConfigPayload,
    firstAlert = false,
  ) {
    const notifConfigUUID = alertConfig.notifConfigs?.[0];
    if (
      JSON.stringify(data.schedule) !== JSON.stringify(alertConfig.schedule) &&
      alertConfig.uuid
    ) {
      await AlertNotifService.markAsHealthyByAlertConfig(alertConfig.uuid);
    }
    if (!notifConfigUUID) {
      return;
    }
    const payload = {
      ...alertConfig,
      ...data,
      ...formValue,
      isRecommendedAlert: hasRecommendedAlert(deviceUUID),
    };

    const equipmentData = equipment.byUUID[equipmentUUID as string];
    payload.triggerValue = getEquipmentTriggerValue(
      payload,
      equipmentData as EquipmentPayloadData,
    );

    alertConfig.uuid &&
      (await editAlertConfig(payload, alertConfig.uuid, notifConfigUUID));
    hideEdit();

    if (firstAlert && !isEditing && data.deviceUUID) {
      showAddMoreSensorModal(data.deviceUUID);
    }
  }

  function deleteConfig(): void {
    openDeleteDialog({
      content: (
        <>
          Are you sure you want to delete&nbsp;
          <strong>{`${deviceName}'s Alert ${+index + 1}`}</strong>?
        </>
      ),
      onConfirm: async () => {
        alertConfig.uuid &&
          (await deleteAlertConfig(deviceUUID, alertConfig.uuid));
        removeActiveForm();
        closeDialog();
      },
      disableButtonsOnQuery: 'alertConfig:delete',
    });
  }

  function showEdit(): void {
    resetConfig();
    setIsEditing(true);
    addActiveForm();
  }

  function handleAlertConfigCancel(): void {
    setHasActiveAlert(!!get(alertConfig, 'schedule.rules.length', 0));
    resetConfig();
    hideEdit();
  }

  function duplicateAlert() {
    const { methods, recipients } = generateDefaultValues(true);
    const equipmentData = equipment.byUUID[equipmentUUID as string];
    const triggerValue = getEquipmentTriggerValue(
      alertConfig,
      equipmentData as EquipmentPayloadData,
    );

    addAlertConfig({
      ...alertConfig,
      methods,
      recipients,
      isSmartAlert,
      triggerValue,
      meta: {
        isRecommendedAlert: false,
      },
    });
  }

  async function toggleState(): Promise<void> {
    if (!isDeviceActive()) {
      return;
    }

    try {
      const newStatus = isAlertActive ? 'disabled' : 'active';
      /**
       * * Get alert config for equipment based edit
       * * since the API doesn't support only updating the status
       * * and pass this to the edit endpoint
       */
      const alertConfigData =
        store.entities.alertConfigs.byUUID[alertConfig.uuid as string];

      const equipmentData = equipment.byUUID[equipmentUUID as string];
      alertConfigData.triggerValue = getEquipmentTriggerValue(
        alertConfigData,
        equipmentData as EquipmentPayloadData,
      );
      if (isAlertSnoozed() && newStatus === 'active' && alertConfig.uuid) {
        await editAlertConfig(
          {
            ...alertConfigData,
            status: newStatus,
            snoozeEndAt: null,
          },
          alertConfig.uuid,
          undefined,
        );
        setAlertStatus({
          alertConfigUUID: alertConfig.uuid,
          status: newStatus,
          snoozeEndAt: null,
        });
        return setAlertActive(!isAlertActive);
      } else if (isAlertSnoozed() && alertConfig.uuid) {
        // This statement would have the alert config values with
        // status = active
        // snoozeEndAt = not expired date.
        // We need only to set the snoozeEndAt to null to reactivate the alert.
        await editAlertConfig(
          {
            ...alertConfigData,
            snoozeEndAt: null,
          },
          alertConfig.uuid,
          undefined,
        );

        return setAlertStatus({
          alertConfigUUID: alertConfig.uuid,
          snoozeEndAt: null,
        });
      }
      await editAlertConfig(
        {
          ...alertConfigData,
          status: newStatus,
        },
        alertConfig.uuid as string,
        undefined,
      );
      setAlertStatus({
        alertConfigUUID: alertConfig.uuid as string,
        status: newStatus,
      });

      return setAlertActive(!isAlertActive);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Error in updating alert status: ${err}`);
    }
  }

  const formDefaultValues = useMemo(() => generateDefaultValues(), [
    alertConfig,
    notifConfigs,
  ]);

  return (
    <AlertLineItem
      key={index}
      dragEnabled={isDragAllowed && index !== 0 && !isReorderInProgress}
    >
      <AlertListHeading
        index={index}
        deviceName={deviceName}
        hasActiveAlerts={hasActiveAlert}
        alertFields={isEditing ? {...formDefaultValues, ...formValue} : formDefaultValues}
        preferredTempUnit={user?.preferredTempUnit}
        showFormCloseBtn={isEditing}
        onAlertFormCancel={handleAlertConfigCancel}
        isEditing={isEditing}
        alertConfigUUID={alertConfig.uuid}
        equipmentUUID={equipmentUUID}
      >
        {!isEditing && (
          <>
            {alertConfig.field !== 'battery' && (
              <Tooltip
                content="Duplicate alert"
                trigger={
                  <Button
                    disabled={
                      hasActiveForm() ||
                      isQueryInProgress['alertConfig:add'] ||
                      isReorderInProgress
                    }
                    floated="right"
                    circular
                    icon
                    onClick={duplicateAlert}
                    className="therma-off-white"
                  >
                    <Icon
                      size="large"
                      className="icon-duplicate"
                      aria-label="Duplicate alert"
                    />
                  </Button>
                }
              />
            )}

            <Tooltip
              content="Edit"
              trigger={
                <Button
                  disabled={hasActiveForm() || isReorderInProgress}
                  floated="right"
                  circular
                  icon
                  onClick={showEdit}
                  className="therma-off-white"
                >
                  <Icon
                    size="large"
                    className="icon-pencil"
                    aria-label="Edit alert"
                  />
                </Button>
              }
            />
            <PowerButton
              itemName="Alert"
              isActive={!isAlertSnoozed() && isAlertActive && isDeviceActive()}
              onClick={toggleState}
              isQueryInProgress={
                isQueryInProgress[`alertService:edit:${alertConfig.uuid}`] ||
                isReorderInProgress
              }
              disabled={hasActiveForm() || isReorderInProgress}
            />
          </>
        )}
      </AlertListHeading>
      {isEditing && (
        <AlertConfigFormContainer size="20px 75px 20px 74px">
          <FormContext {...formMethods}>
            <AlertConfigForm
              deviceUUID={deviceUUID}
              deleteButton={
                index !== 0 ? (
                  <Button
                    circular
                    size="huge"
                    icon="trash alternate outline"
                    type="button"
                    onClick={deleteConfig}
                    className="therma-off-white"
                    disabled={
                      isQueryInProgress['alertConfig:add'] ||
                      isQueryInProgress['alertConfig:edit']
                    }
                    data-title="Delete"
                  />
                ) : null
              }
              defaultValues={formDefaultValues}
              onSubmit={(data) => handleAlertConfigEdit(data, index == 0)}
              onCancel={handleAlertConfigCancel}
              hasActiveAlerts={setHasActiveAlert}
              formType="edit"
              equipmentUUID={equipmentUUID}
            />
          </FormContext>
        </AlertConfigFormContainer>
      )}
    </AlertLineItem>
  );
};

import { FormInput } from '@coinspect/ui';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { AccountContext } from '../../../../contexts';
import { Text } from '../../../text';

type SetpointInputProps = {
  alignLabel?: string;
  label?: string;
  fieldSuffix?: '%' | string;
  subText?: string; // text below the field
  className?: string;
  labelClassName?: string;
  name: string;
  inputWrapperStyle?: React.CSSProperties;
  config?: {
    min: number;
    max: number;
  };
  disabled?: boolean;
  defaultValue?: string;
};

const SetpointInputButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 24px;
  height: 24px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #41484d;
  cursor: pointer;
`;

const StyledInputWrapper = styled.div`
  width: 64px;

  > .field {
    margin-bottom: 0 !important;
  }

  .field .ui > input[type='text'] {
    text-align: center;
  }
`;

const SetpointInputLabel = styled.label<{ textAlign: string }>`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: ${(props) => props.textAlign};
`;

export const SetpointInput = (props: SetpointInputProps) => {
  const { user } = useContext(AccountContext);
  const tempUnit = `\u00B0${user?.preferredTempUnit ?? 'f'}`.toUpperCase();
  const {
    label,
    fieldSuffix,
    subText,
    inputWrapperStyle,
    config,
    alignLabel = 'center',
    disabled = false,
    ...rest
  } = props;
  const { setValue, getValues } = useFormContext<Record<string, string>>();
  const handleIncrement = () => {
    const val = Number(getValues(props.name));
    if (config && val === config.max) return;
    setValue(props.name, (val + 1).toString(), true);
  };
  const handleDecrement = () => {
    const val = Number(getValues(props.name));
    if (config && val === config.min) return;
    setValue(props.name, Math.max(0, val - 1).toString(), true);
  };
  const displaySuffix = fieldSuffix ?? tempUnit;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {label && (
        <SetpointInputLabel htmlFor={props.name} textAlign={alignLabel}>
          {label}
        </SetpointInputLabel>
      )}
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <SetpointInputButton onClick={handleDecrement} disabled={disabled}>
          -
        </SetpointInputButton>
        <StyledInputWrapper style={inputWrapperStyle}>
          <FormInput {...rest} />
        </StyledInputWrapper>
        <div style={{ fontSize: '12px' }}>{displaySuffix}</div>
        <SetpointInputButton onClick={handleIncrement} disabled={disabled}>
          +
        </SetpointInputButton>
      </div>
      {subText && <SubText text={subText} />}
    </div>
  );
};

const SubText: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div style={{ marginLeft: '38px', marginTop: '4px' }}>
      <Text useNew asNew="bodySmall" style={{ color: '#A1AAAA' }}>
        {text}
      </Text>
    </div>
  );
};

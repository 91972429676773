import styled from 'styled-components';

import { EnergyDropdown } from '../../dropdown';
export const RecipientDropdown = styled(EnergyDropdown)`
  &&&& {
    .menu {
      visibility: hidden !important;
    }
  }
`;

import styled from 'styled-components';

export const FORM_WIDTHS = '450px';

export const SubHeader = styled.h3`
  font-size: 18px;
  font-weight: 400;
  font-family: proxima-nova;
`;

export const ImageContainer = styled.div`
  width: ${FORM_WIDTHS};
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

export const FormSetpointContainer = styled.div`
  display: flex;
  gap: 155px;
  min-height: 150px; //In order to all have the same size and do not change when error is displayed
`;

import { API_VERSION } from '../../constants';
import { request } from '../../contexts';
import { AlertType } from '../../hooks';
import { AlertConfig, AlertConfigModel, NotifConfig } from '../../services';

export type EquipmentAlertConfigPayloadData = {
  companyUUID: string;
  deviceUUID: string;
  triggerComparator: string;
  field: AlertType;
  triggerValue: number;
  timeframe: number;
  schedule: {
    settings: {
      timezone: string;
    };
    rules: { day: number; fromTime: string; toTime: string }[];
  };
  meta: {
    triggerValue: number;
    hvacSetpointBuffer?: number;
  };
};

type EditBody = Record<string, unknown> &
  Omit<EquipmentAlertConfigPayloadData, 'deviceUUID' | 'schedule'>;

type EditEquipmentAlertConfigPayloadData = {
  alertConfigUUID: string;
} & EditBody;

export type AlertConfigNotifConfig = Omit<NotifConfig, 'alertConfigUUID'>;

export interface BulkAlertConfig extends AlertConfig {
  notifConfig?: AlertConfigNotifConfig;
}

export class EquipmentAlertConfigService {
  public static async browseEquipmentAlertConfig() {
    const { data } = await request.get(`/api/${API_VERSION}/alert-config`);

    return data.data;
  }

  public static async addEquipmentAlertConfig(
    payload: AlertConfig,
    companyUUID: string,
  ) {
    const { data } = await request.post(`/api/${API_VERSION}/alert-config`, {
      ...payload,
      companyUUID,
      meta: {
        ...payload.meta,
        triggerValue: payload.triggerValue,
      },
    });

    return data.data;
  }

  public static async bulkAddEquipmentAlertConfig(
    payload: BulkAlertConfig[],
  ): Promise<AlertConfigModel[]> {
    const { data: alertConfigs } = await request.post<
      {
        alerts: BulkAlertConfig[];
      },
      { data: { data: AlertConfigModel[] } }
    >(`api/${API_VERSION}/alert-config/bulk`, {
      alerts: payload,
    });

    return alertConfigs.data;
  }

  public static async editEquipmentAlertConfig(
    payload: EditEquipmentAlertConfigPayloadData,
    equipmentAlertConfigUUID: string,
  ) {
    const { data } = await request.put(
      `/api/${API_VERSION}/alert-config/${equipmentAlertConfigUUID}`,
      {
        ...payload,
      },
    );
    return data.data;
  }

  public static async deleteEquipmentAlertConfig(
    equipmentAlertConfigUUID: string,
  ) {
    const { data } = await request.delete(
      `/api/${API_VERSION}/alert-config/${equipmentAlertConfigUUID}`,
    );

    return data.data;
  }

  // * We're just going to fetch all alert configs and map them to their related device
  // public static async browseEquipmentAlertConfigsByDevice(
  //   deviceUUIDs: string[],
  //   cancelToken?: CancelToken,
  // ) {
  //   const { data: alertConfigs } = await request.post(
  //     `/api/${API_VERSION}/alert-config/by-device-uuid`,
  //     {
  //       deviceUUIDs,
  //       cancelToken,
  //     },
  //   );

  //   return {
  //     alertConfigs: [...alertConfigs.data],
  //   };
  // }
}

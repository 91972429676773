import { Form } from '@coinspect/ui';
import { isValidEmail, isValidPhoneNumber } from '@coinspect/utils';
import { toSentenceCase } from 'common-utils-pkg';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, FormContext, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Icon, StrictDropdownProps } from 'semantic-ui-react';

import { useInviteService, useTeamService } from '../../../hooks';
import { StoreContext } from '../../../store';
import { EnergyFieldErrorStyle } from '../../../style/global-energy-styles';
import { snackbar } from '../../../utils/toast';
import { Button } from '../../buttons';
import { EnergyDropdown } from '../../dropdown';
import ErrorBoundary from '../../error-boundary/error-boundary';
import { Text } from '../../text';
import { FormToggle } from '../../toggle';
import { FORM_WIDTHS } from '../create-team-page/create-team-page';
import EnergyMobileNav from '../energy-dashboard-page/energy-mobile-nav';
import EnergyNav from '../energy-dashboard-page/energy-nav';
import ArrowIcon from '../energy-dashboard-page/icons/ArrowIcon';
import { EnergyReportsMainContent } from '../energy-reports-add/styles';
import {
  EnergyReportsHeader,
  EnergyReportsHeaderMenu,
  EnergyReportsHeaderText,
  ReportsContent,
  ReportsPageContainer,
} from '../energy-reports-page/index-styles';
import { toAddPeoplePayload } from './add-people-page-utils';
import { RecipientDropdown } from './styles';

const phonePrefix = ['+1', '+30', '+44', '+51', '+52', '+63', '+64'];
const phonePrefixOptions = phonePrefix.map((prefix) => {
  return { text: prefix, value: prefix };
});

const textMappings = {
  email: {
    label: 'email address',
  },
  sms: {
    label: 'phone number',
  },
};

export type AddPeopleFormData = {
  role: 'user' | 'admin';
  type: 'email' | 'sms';
  teams?: string[];
  recipients: string[];
  countryCode?: typeof phonePrefix[number];
};

export const AddPeoplePage = () => {
  const history = useHistory();
  const { browseTeam } = useTeamService();
  const { addInvite } = useInviteService();
  const { store } = useContext(StoreContext);
  const isQueryInProgress = store.pages.isQueryInProgress;
  const allTeams = Object.values(store.pages.teams.byUUID);

  useEffect(() => {
    browseTeam();
  }, []);

  const formMethods = useForm<AddPeopleFormData>({
    mode: 'onChange',
    defaultValues: {
      role: 'user',
      type: 'email',
      recipients: [],
      teams: [],
    },
  });

  const [recipients, setRecipients] = useState<
    { value: string; text: string }[]
  >([]);
  const [values, setValues] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [hasRecipientError, setHasRecipientError] = useState(false);

  const recipientDropdownRef = useRef<HTMLDivElement>(null);

  const [dropdownHeight, setDropdownHeight] = useState<number>();

  useEffect(
    () => {
      if (!recipientDropdownRef.current) {
        // we do not initialize the observer unless the ref has
        // been assigned
        return;
      }

      // we also instantiate the resizeObserver and we pass
      // the event handler to the constructor
      const resizeObserver = new ResizeObserver(() => {
        // if (recipientDropdownRef.current.offsetWidth !== width) {
        //   setWidth(recipientDropdownRef.current.offsetWidth);
        // }
        if (
          recipientDropdownRef?.current?.offsetHeight &&
          recipientDropdownRef?.current?.offsetHeight !== dropdownHeight
        ) {
          setDropdownHeight(recipientDropdownRef.current.offsetHeight);
        }
      });

      // the code in useEffect will be executed when the component
      // has mounted, so we are certain recipientDropdownRef.current will contain
      // the div we want to observe
      resizeObserver.observe(recipientDropdownRef.current);

      // if useEffect returns a function, it is called right before the
      // component unmounts, so it is the right place to stop observing
      // the div
      return function cleanup() {
        resizeObserver.disconnect();
      };
    },
    // only update the effect if the ref element changed
    [recipientDropdownRef.current],
  );

  const watchType = formMethods.watch('type');
  const watchPrefix = formMethods.watch('countryCode');
  const checker =
    watchType === 'email'
      ? isValidEmail
      : (val: string) => isValidPhoneNumber(`${watchPrefix}${val}`);
  const getBaseValue = (val: string) =>
    watchType === 'email'
      ? val
      : `${(val.trim() || '').replace(/[^0-9]/gi, '')}`;
  const formatValue = (val: string) =>
    watchType === 'email'
      ? val
      : `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6, 10)}`;

  useEffect(() => {
    // clear value on type change
    setRecipients([]);
    setValues([]);
    setSearchQuery('');
    setHasRecipientError(false);
  }, [watchType]);

  const handleCancel = () => {
    history.replace('/energy/people');
  };

  const onBlurRecipient: StrictDropdownProps['onBlur'] = (_e, param2) => {
    const currentValue = (_e.target as HTMLInputElement).value;

    // avoid check the current value if is empty and we already have valid recipients
    if (values.length > 0 && currentValue === '') {
      setHasRecipientError(false);
      return null;
    }

    const isEmailorPhoneValid = checker(currentValue);

    if (!isEmailorPhoneValid) {
      return setHasRecipientError(true);
    }

    setRecipients((previousOptions) => {
      const baseVal = getBaseValue(param2.searchQuery as string);

      return [
        {
          text: formatValue(baseVal),
          value: baseVal,
        },
        ...previousOptions,
      ];
    });

    setValues((previousOptions) => {
      return [...previousOptions, getBaseValue(param2.searchQuery as string)];
    });

    setSearchQuery('');
    setHasRecipientError(false);
  };

  const onSubmit = async (data: AddPeopleFormData) => {
    try {
      const payload = toAddPeoplePayload(data, recipients);
      await addInvite(payload);
      handleCancel();
      snackbar.open('Invites sent successfully');
    } catch (e) {
      snackbar.open('Something went wrong when sending invite');
    }
  };

  return (
    <ErrorBoundary>
      <ReportsPageContainer>
        <EnergyNav page="PEOPLE" />
        <EnergyMobileNav page="PEOPLE" />
        <ReportsContent>
          <EnergyReportsHeader>
            <EnergyReportsHeaderMenu>
              <EnergyReportsHeaderText>
                <Link to="/energy/people">
                  <ArrowIcon
                    style={{
                      height: '24px',
                      transform: 'rotate(270deg)',
                      width: '24px',
                    }}
                  />
                </Link>
                Add people
              </EnergyReportsHeaderText>
            </EnergyReportsHeaderMenu>
          </EnergyReportsHeader>
          <FormContext {...formMethods}>
            <Form>
              <EnergyReportsMainContent>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div>
                    <p>
                      {`Invite one or more people through email or text message.
                      Use teams to group people as alert recipients and manage
                      users' views and permissions.`}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div
                    style={{
                      width: FORM_WIDTHS,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ fontSize: '12px' }}>Send invite via</p>
                      <FormToggle
                        name="type"
                        options={[
                          {
                            label: 'Email',
                            value: 'email',
                            className: 'invite-option',
                          },
                          {
                            label: 'SMS',
                            value: 'sms',
                            className: 'invite-option',
                          },
                        ]}
                        isNew
                        required
                      />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ fontSize: '12px' }}>Assign role</p>
                      <FormToggle
                        name="role"
                        options={[
                          {
                            label: 'Viewer',
                            value: 'user',
                            className: 'role-option',
                          },
                          {
                            label: 'Admin',
                            value: 'admin',
                            className: 'role-option',
                          },
                        ]}
                        isNew
                        required
                      />
                    </div>
                  </div>
                  <div style={{ lineHeight: '35px', marginTop: '32px' }}>
                    {formMethods.watch('role') === 'user'
                      ? `Viewers cannot edit equipment, alerts, people, teams, or
                    view savings`
                      : `Admins have all view and edit access except subscription and payment`}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div style={{ width: FORM_WIDTHS, display: 'flex' }}>
                    {watchType === 'sms' && (
                      <div style={{ width: '100%' }}>
                        <Controller
                          as={
                            <EnergyDropdown
                              options={phonePrefixOptions}
                              fluid
                              style={{
                                height: `${dropdownHeight}px`,
                                borderRight: '2px solid #41484D',
                              }}
                            />
                          }
                          name="countryCode"
                          onChange={(event) => event[1].value}
                          defaultValue={phonePrefix[0]}
                        />
                      </div>
                    )}
                    <div>
                      <div ref={recipientDropdownRef}>
                        <RecipientDropdown
                          icon={
                            hasRecipientError ? (
                              <Icon
                                className="icon-error"
                                style={{
                                  color: EnergyFieldErrorStyle.color,
                                  position: 'absolute',
                                  right: 0,
                                }}
                              />
                            ) : null
                          }
                          style={{
                            width: watchType === 'sms' ? '360px' : '450px',
                          }}
                          options={recipients}
                          text={toSentenceCase(textMappings[watchType].label)}
                          label={toSentenceCase(textMappings[watchType].label)}
                          search
                          selection
                          fluid
                          multiple
                          allowAdditions
                          onBlur={onBlurRecipient}
                          value={values}
                          searchQuery={searchQuery}
                          onSearchChange={(_e, param2) => {
                            setSearchQuery(param2.searchQuery);
                            setHasRecipientError(false);
                          }}
                          onAddItem={(_e, param2) => {
                            if (!checker(param2.value as string)) {
                              return setHasRecipientError(true);
                            }
                            setRecipients((previousOptions) => {
                              setSearchQuery('');

                              const baseVal = getBaseValue(
                                param2.value as string,
                              );

                              return [
                                {
                                  text: formatValue(baseVal),
                                  value: baseVal,
                                },
                                ...previousOptions,
                              ];
                            });
                            setHasRecipientError(false);
                          }}
                          onChange={(_e, param2) => {
                            setValues(param2.value.map(getBaseValue));
                          }}
                          error={hasRecipientError}
                        />
                      </div>
                      <Text
                        useNew
                        asNew="bodySmall"
                        style={{
                          marginLeft: '15px',
                          color: hasRecipientError
                            ? EnergyFieldErrorStyle.color
                            : 'inherit',
                        }}
                      >
                        {!hasRecipientError &&
                          `Please enter after each ${textMappings[watchType].label} added`}
                        {hasRecipientError &&
                          `Invalid ${textMappings[watchType].label}`}
                      </Text>
                    </div>
                  </div>

                  <div>
                    <Controller
                      as={
                        <EnergyDropdown
                          text="Optional team"
                          options={allTeams.map((team) => {
                            return {
                              value: team.uuid,
                              text: team.name,
                            };
                          })}
                          multiple
                          selection
                          search
                          label="Optional team"
                          width={FORM_WIDTHS}
                          error={!!formMethods.errors['teams']}
                        />
                      }
                      name="teams"
                      defaultValue={[]}
                      onChange={(event) => event[1].value}
                    />
                    <Text
                      useNew
                      asNew="bodySmall"
                      style={{ marginLeft: '15px' }}
                    >
                      Assign to a team to manage views and use as alert
                      recipients
                    </Text>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '40px',
                    justifyContent: 'flex-end',
                    marginTop: '250px',
                  }}
                >
                  <Button
                    onClick={handleCancel}
                    as="text"
                    disabled={isQueryInProgress['invite:add']}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={formMethods.handleSubmit((data) => {
                      onSubmit(data);
                    })}
                    as="filled"
                    disabled={
                      !formMethods.formState.isValid ||
                      recipients.length < 1 ||
                      isQueryInProgress['invite:add']
                    }
                    loading={isQueryInProgress['invite:add']}
                  >
                    Send invite
                  </Button>
                </div>
              </EnergyReportsMainContent>
            </Form>
          </FormContext>
        </ReportsContent>
      </ReportsPageContainer>
    </ErrorBoundary>
  );
};
